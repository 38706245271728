import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { RuleTypesModel } from "src/app/dashboard/models/rules.model";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormsModel } from "src/app/models/forms.model";
import {
  RULES_FIELDS,
  extraFields,
  uniqueFields
} from "../../models/rules-fields";

@Component({
  selector: "app-rules-selector",
  templateUrl: "./rules-selector.component.html",
  styleUrls: ["./rules-selector.component.scss"]
})
export class RulesSelectorComponent implements OnInit, OnChanges {
  @Input() rules: RuleTypesModel[] = [];
  @Input() editRule;
  @Input() newRule;
  @Output() saveRule = new EventEmitter();
  @Input() fileFields: any[];
  @Input() uniqueDates:any[]
  extraFormModel: FormsModel = null;
  extraRulesForm: FormGroup = null;
  ruleTypes: { key: string | number; value: string }[];
  rulesForm: FormGroup = null;
  fields: { key: string | number; value: string }[];
  extraFormFields: any[];
  allFields: any;
  enableEdit: boolean;
  ingestRuleId:number;
  constructor() {
    this.rulesForm = new FormGroup({
      ingestruletypeId: new FormControl("", [Validators.required]),
      ruleName: new FormControl(null, [Validators.required]),
      appliesTo: new FormControl(null, [Validators.required])
    });
  }
  ngOnInit(): void {
    this.enableEdit = false;
    this.extraFormFields = extraFields();
    this.allFields = uniqueFields();
    this.rulesForm.get("ingestruletypeId").valueChanges.subscribe(v => {
     if(!this.enableEdit){
       this.ingestRuleId=null
     }
      if (v && v !== "" && v.key) {
        const definition = this.getRuleDefiniton(v.key);
        this.checkType(v.key);
      }
    });
  }
  checkType(t) {
    switch (Number(t)) {
      // compare
      case 4:
      console.log(this.editRule);
      RULES_FIELDS.compare.controls[1].options=this.uniqueDates;
      RULES_FIELDS.compare.controls[0].options=this.fields; 
         
      this.extraFormModel = RULES_FIELDS["compare"] as FormsModel;
    
        break;
      // format
      case 5:
        this.extraFormModel = RULES_FIELDS["format"] as FormsModel;
        break;
      // manual intervention
      case 12:
        this.extraFormModel = RULES_FIELDS["manual"] as FormsModel;
        break;
      // remove
      case 3:
        this.extraFormModel = RULES_FIELDS["remove"] as FormsModel;
        break;
      // Remove (Duplicate)
      case 10:
        this.extraFormModel = RULES_FIELDS["removeDuplicate"] as FormsModel;
        break;
      // Remove (Predefined)
      case 8:
        this.extraFormModel = RULES_FIELDS["removePredefined"] as FormsModel;
        break;
      // Remove (Special Character)
      case 11:
        this.extraFormModel = RULES_FIELDS["removeSpecialChar"] as FormsModel;
        break;
      // Translate Value
      case 1:
        this.extraFormModel = RULES_FIELDS["translate"] as FormsModel;
        break;
      // Trim
      case 6:
        this.extraFormModel = RULES_FIELDS["trim"] as FormsModel;
        break;
      // Update
      case 2:
        this.extraFormModel = RULES_FIELDS["update"] as FormsModel;
        break;
      // Update (Predefined)
      case 9:
        this.extraFormModel = RULES_FIELDS["updatePredefined"] as FormsModel;
        break;
        
      // Update All
      case 7:
        this.extraFormModel = RULES_FIELDS["updateAll"] as FormsModel;
        break;
      // Validation
      case 13:
        this.extraFormModel = RULES_FIELDS["validation"] as FormsModel;
        break;
    }
  }
  getRuleDefiniton(ruleType) { 
    return JSON.parse(
      this.rules.find(r => r.ingestruletype_id === ruleType).ruleDefinition
    );
  }
  formatRuleTypes(rules) {
    
    return reMapForAutoComplete(rules, "ingestruletype_id", "displayName");
  }
  formatFields(fields) {
    return reMapForAutoComplete(fields, "clientFileLayoutFieldId", "fieldName");
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.rules && changes.rules.currentValue) {
      this.ruleTypes = this.formatRuleTypes(changes.rules.currentValue);
           
    }
    if (changes && changes.fileFields && changes.fileFields.currentValue) {
      this.ingestRuleId=null;     
      this.fields = this.formatFields(changes.fileFields.currentValue);    
    }
    if (changes && changes.editRule && changes.editRule.currentValue) {
      const rule = changes.editRule.currentValue;  
      this.ingestRuleId=rule.ingestruleId;    
      this.checkType(rule.ingestruletypeId);
      this.enableEdit = true;
    }
    if (changes && changes.newRule && changes.newRule.currentValue > 1) {
      this.resetForm();
    }
  }
  resetForm() {
    this.rulesForm.reset();
    this.rulesForm.patchValue({ ingestruletypeId: "" });
    if(this.extraRulesForm!=null){
      this.extraRulesForm.reset();
    }
   
    this.editRule = this.extraFormModel = null;
  }
  getForm(form: FormGroup) {
    this.extraRulesForm = form;
    this.checkForEdit();
  }
  reFormatValues(value) {
    return { key: value, value };
  }
  checkForEdit() {
    if (this.editRule && this.enableEdit) {
      const appliesTo = this.fields.find(
        t => t.value === this.editRule.definition.appliesTo
      );
      this.rulesForm.patchValue({
        ingestruletypeId: this.reFormatValues(this.editRule.ingestruletypeId),
        ruleName: this.editRule.ruleName,
        appliesTo
      });
      this.getExtraFieldsToPatch(this.editRule.ingestruletypeId);
      this.enableEdit = false;
     
    }
  }
  findField(t) {
    const { fields } = this.extraFormFields.find(d => d.key === t);
    return this.convertArrayToField(fields);
  }
  convertArrayToField(field) {
    let obj = {};
    for (let f of field) {
      obj[f] = null;
    }
    return obj;
  }
  checkDropdownField(o) {
    return (
      o === "errorValue" ||
      o === "fieldType" ||
      o === "replaceValue" ||
      o === "operator" ||
      o === "updateValue"||
      o === "compareTo"||
      o === "asOfDate"
    );
  }
  mapValuesToObject(obj) {
    let data = {};
    for (let o in obj) {
      if (this.checkDropdownField(o)) {
        data[o] = this.reFormatValues(this.editRule.definition[o]);      
      } else {
        data[o] = this.editRule.definition[o];
      }
    }
    return data;
  }
  getExtraFieldsToPatch(type) {
    let data = null;
    switch (Number(type)) {
      // compare
      case 4:
        data = this.findField("compare");
        break;
      // format
      case 5:
        data = this.findField("format");
        break;
      // manual intervention
      case 12:
        data = this.findField("manual");
        break;
      // remove
      case 3:
        data = this.findField("remove");
        break;
      // Remove (Duplicate)
      case 10:
        data = this.findField("removeDuplicate");
        break;
      // Remove (Predefined)
      case 8:
        data = this.findField("removePredefined");
        break;
      // Remove (Special Character)
      case 11:
        data = this.findField("removeSpecialChar");
        break;
      // Translate Value
      case 1:
        data = this.findField("translate");
        break;
      // Trim
      case 6:
        data = this.findField("trim");
        break;
      // Update
      case 2:
        data = this.findField("update");      
        break;
      // Update (Predefined)
      case 9:
        data = this.findField("updatePredefined");
        break;
      // Update All
      
      case 7:
        data = this.findField("updateAll");
        break;
      // Validation
      case 13:
        data = this.findField("validation");
        break;
    }
    data = this.mapValuesToObject(data);
    this.extraRulesForm.patchValue(data);
  }
  removeKeyValueFormat(data) {
    let result = {};
    try {
      for (let o in data) {
        if(data[o]==null){
          result[o] = null;
        }
        else if (data[o].key && data[o]!=undefined && data[o]!=null) {        
          result[o] = data[o].key;   
        } else {         
          result[o] = data[o];         
        }
      }
      
      return result;
    } catch {
      return data;
    }
  }
  saveData() {
   
    let data = {
      ...this.removeKeyValueFormat(this.rulesForm.value),
      definition: {
        ...this.allFields,
        ...this.removeKeyValueFormat(this.extraRulesForm.value)
      }
    };  
   this.saveRule.emit({
     dataValue:data,
     ingestruleId:this.ingestRuleId
   });
  }


 
}
