import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PopUpModel } from '../models/lookup.model';

@Injectable()
export class LookupService {
  constructor(private http: HttpClient) {}
  /**
   * @param  {number} id
   */
  created_by: number;
  
  getLookupExcel(modalForm){
   // console.log("modalForm " +modalForm.created_by);
   var payload = new FormData();
   payload.append("created_by", "1")
   payload.append("insert_data","0");
   payload.append("connection_id","2");
   payload.append("client_id",modalForm.client_id);
   payload.append("check_only","0");
   payload.append("details","0");
   payload.append("asofdate",modalForm.asofdate);
    return this.http.post<PopUpModel[]>(
      `${environment.baseUrl}/api/etl/lookup/validation/`,payload
    );  
  }
}
