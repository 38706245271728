import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ToastService } from "src/app/core/services/toast.service";
import { FileService } from "src/app/files/services/file.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import {
  FileLayoutModel,
  FieldLayoutModel,
} from "../../models/file-layout.model";
import { isEmpty } from "lodash";
import { FieldsLayoutFormComponent } from "src/app/files/components/fields-layout-form/fields-layout-form.component";

@Component({
  selector: "app-file-layout",
  templateUrl: "./file-layout.component.html",
  styleUrls: ["./file-layout.component.scss"],
})
export class FileLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(FieldsLayoutFormComponent)
  fieldLayoutComp: FieldsLayoutFormComponent;
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientId: number = null;
  sheets: { key: string | number; value: string }[] = [];
  fileLayout: FileLayoutModel;
  layoutFields: FieldLayoutModel[];
  headerRows = [];
  editFieldValue: FieldLayoutModel = null;
  showEditButton:boolean=false

  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private loader: LoaderService,
    private fileService: FileService,
    private toast: ToastService
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      file: new FormControl(null, [Validators.required]),
      header: new FormControl(null, [Validators.required]),
      headerRow: new FormControl(null, [Validators.required]),
      sheet: new FormControl(null, [Validators.required]),
    });
    this.createHeaderRows(6);
  }
  ngOnInit(): void {
    this.setTitle();
  }
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
  }
  get showButton() {
    return this.clientId !== null && this.sheets.length > 0;
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  createHeaderRows(count) {
    for (let i = 0; i < count; i++) {
      this.headerRows.push({ key: i, value: i });
    }
  }
  getFiles() {
    this.editFieldValue = null;
    this.sheets = this.layoutFields = [];
    this.fileLayout = null;
    this.fieldLayoutComp.fieldLayoutForm.reset();
    this.clientId = this.headerForm.value.client.key;
    this.headerForm.get("file").reset();
  }
  getSheets(client, id) {
    this.sheets = [];
    this.loader.showLoader();
    this.fileService.getFileSheets(client.key, id).subscribe(
      (data) => {
        console.log(data);
        this.sheets = reMapForAutoComplete(data, "sheetName", "sheetName");
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
      }
    );
  }
  getFileLayout(id) {
    this.fieldLayoutComp.fieldLayoutForm.reset();
    this.showEditButton=false;
    const { client } = this.headerForm.value;
    this.headerForm.patchValue({
      header: 0,
      headerRow: null,
    });
    this.sheets = this.layoutFields = [];
    this.fileLayout = null;
    this.loader.showLoader();
    this.fileService.getFileLayout(client.key, id).subscribe(
      (data) => {
        if (data === null) {
          this.getSheets(client, id);
          this.toast.showInfo("No Layout defined", "Select a sheet");
        } else {
          this.fileLayout = data;
          this.showEditButton=true
          this.headerForm.patchValue({
            header: this.fileLayout.hasHeaders,
            headerRow: {
              key: this.fileLayout.noOfHeaders,
              value: this.fileLayout.noOfHeaders,
            },
          });
          this.getFieldLayout(this.fileLayout.clientFileLayoutId);
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError("Server Error", "Error fetching data");
        this.loader.hideLoader();
      }
    );
  }
  getFieldLayout(id) {
    this.loader.showLoader();
    this.fileService.getFileLayoutById(id).subscribe(
      (data) => {
        this.layoutFields = data;
        console.log("before" + data);
        console.log(this.layoutFields);
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
      }
    );
  }
  getSheetHeaders() {
    if (this.headerForm.valid) {
      this.loader.showLoader();
      const { client, file, header, headerRow, sheet } = this.headerForm.value;
      this.fileService
        .getSheetHeaders({
          clientApplicationFileId: file.key,
          clientId: client.key,
          headerRows: +header,
          noOfHeaders: headerRow.key,
          sheetName: sheet.key,
        })
        .subscribe(
          (data) => {
            this.layoutFields = data;
            this.loader.hideLoader();
          },
          (err) => {
            this.loader.hideLoader();
          }
        );
    } else {
      this.toast.showError("Form Error", "Please select all the fields");
    }
  }
  editField(data: FieldLayoutModel) {
    this.editFieldValue = data;
   // console.log(data);
  }
  deleteField(data: FieldLayoutModel) {
   this.fileService.deleteFileLayoutFieldById(data.clientFileLayoutFieldId)
   .subscribe(
     res=>{
       this.toast.showSuccess("deleted","success");
       this.editFieldValue = null;
       this.fieldLayoutComp.fieldLayoutForm.reset();
       this.getFileLayout(this.headerForm.controls.file.value.key);
     }
   )
  }
  addField(data) {

    
    const fileLayoutFieldData = {
      clientFileLayoutFieldId:this.editFieldValue !== null? this.editFieldValue.clientFileLayoutFieldId: null,
      clientFileLayoutId: this.fileLayout.clientFileLayoutId,
      fieldName: data.fieldName,
      fieldType: data.fieldType,
      createdAt:this.editFieldValue !== null ? this.editFieldValue.createdAt : null,
      sequence: this.editFieldValue !== null ? this.editFieldValue.sequence : 0,
      fieldFormat:  data.fieldFormat !== null ? data.fieldFormat : null,
      fieldSize:data.fieldSize !== null ? data.fieldSize : 0,
    };
    this.fileService
      .saveOrUpdateFileLayoutField(fileLayoutFieldData)
      .subscribe((data) => {
        this.toast.showSuccess("Field Saved", "success");
        this.editFieldValue=null;
        this.fieldLayoutComp.fieldLayoutForm.reset();
        this.getFileLayout(this.headerForm.controls.file.value.key);
      });
  }
  saveLayout() {
    
    const { client, file, header, headerRow, sheet } = this.headerForm.value;
    
    if(file===null || file===undefined){
         this.toast.showError('error','file mandatory');
         return;
    }
  
    let sheetName="Sheet1"

   if(this.fileLayout!=null || this.fileLayout!=undefined ) {
    sheetName=this.fileLayout.sheetName;
   }

   let layoutFieldsData:FieldLayoutModel[]=[];

   if(this.layoutFields.length>0){
   for(let i=0;i<this.layoutFields.length;i++){

        const fieldData={
          "clientFileLayoutFieldId": this.layoutFields[i].clientFileLayoutFieldId,
          "clientFileLayoutId":this.layoutFields[i].clientFileLayoutId,
          "fieldName": this.layoutFields[i].fieldName,
          "fieldType":this.layoutFields[i].fieldType,
          "comments":this.layoutFields[i].comments,
          "sequence":i+1,
          "modifiedBy":this.layoutFields[i].modifiedBy,
          "modeifiedAt": this.layoutFields[i].modeifiedAt,
          "createdBy": this.layoutFields[i].createdBy,
          "createdAt":this.layoutFields[i].createdAt,
          "fieldFormat": this.layoutFields[i].fieldFormat,
          "fieldSize":this.layoutFields[i].fieldSize,
        }
        layoutFieldsData.push(fieldData);
   }
  }


   if (isEmpty(this.layoutFields)) {
    const fieldData={
      "clientFileLayoutFieldId": null,
      "clientFileLayoutId":null,
      "fieldName": "Account No",
      "fieldType":"STRING",
      "comments":null,
      "sequence":1,
      "modifiedBy":1,
      "modeifiedAt": null,
      "createdBy": 1,
      "createdAt":null,
      "fieldFormat": null,
      "fieldSize":0,
    }
    layoutFieldsData.push(fieldData);
  }
    
  // const { client, file, header, headerRow, sheet } = this.headerForm.value;
    const fileLayoutData = {
      clientFileLayoutId:this.fileLayout !== null ? this.fileLayout.clientFileLayoutId : null,
      clientApplicationFileId: file.key,
      sheetName: sheet !== null ? sheet.key :sheetName,
      hasHeaders: header ? 1 : 0,
      noOfHeaders: headerRow !== null ? headerRow.key : 0,
      createdBy: 1,
      fileLayoutField: layoutFieldsData,
    };

    this.fileService.saveFileLayout(fileLayoutData).subscribe((data) => {
      this.toast.showSuccess('success','layout saved');
      this.headerForm.reset();
      this.layoutFields = [];
     
    },
    (err)=>{
      this.toast.showError('error','error saving data')
    });
  }
  getFirstField(data) {
    console.log(data);
  }
}
