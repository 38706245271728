import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MenuService {
  constructor(private http: HttpClient) {}

  getMenuItems() {
    return this.http.get<MenuItem[]>("/assets/menu.json");
  }
}
