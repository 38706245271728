import { NgModule, ModuleWithProviders } from "@angular/core";
import { LoginComponent } from "./containers/login/login.component";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { AuthService } from "./services/auth.service";
import { UserListComponent } from './components/user-list/user-list.component';
import { UserOpsComponent } from './components/user-ops/user-ops.component';


@NgModule({
  declarations: [LoginComponent, UserListComponent, UserOpsComponent],
  imports: [SharedModule, UiModule],
  exports: [LoginComponent, UserListComponent, UserOpsComponent]
})
export class UserModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserModule,
      providers: [AuthService]
        };
  }
}
