import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextboxComponent } from "./components/textbox/textbox.component";
import { FormbuilderComponent } from "./containers/formbuilder/formbuilder.component";
import { InputTextModule } from "primeng/inputtext";
import { ReactiveFormsModule } from "@angular/forms";
import { ValidatorsModule } from "../validators/validators.module";
import { CalendarModule } from "primeng/calendar";

import { ButtonComponent } from "./components/button/button.component";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DropDownComponent } from "./components/drop-down/drop-down.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { RadioComponent } from "./components/radio/radio.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { CheckListComponent } from "./components/check-list/check-list.component";

@NgModule({
  declarations: [
    TextboxComponent,
    FormbuilderComponent,
    ButtonComponent,
    AutoCompleteComponent,
    DropDownComponent,
    CalendarComponent,
    RadioComponent,
    CheckboxComponent,
    CheckListComponent
  ],
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ValidatorsModule,
    AutoCompleteModule,
    CalendarModule
  ],
  exports: [
    TextboxComponent,
    FormbuilderComponent,
    ButtonComponent,
    AutoCompleteComponent,
    DropDownComponent,
    CalendarComponent,
    ReactiveFormsModule,
    RadioComponent,
    CheckboxComponent,
    CheckListComponent
  ]
})
export class FormsyModule {}
