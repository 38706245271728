import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-modal-popup",
  templateUrl: "./modal-popup.component.html",
  styleUrls: ["./modal-popup.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalPopupComponent implements OnInit {
  @Input() visible: boolean;
  @Input() header: string;
  @Input() infoPopup: boolean;
  @Output() decision = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onClick(type) {
    this.decision.emit(type);
  }
}
