import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  authState: any = null;
  private _accessToken: string;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.authState.subscribe(auth => {
      this.authState = auth;
      this.userToken();
    });
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  // Returns current user data
  get currentUser(): any {
    return this.afAuth.authState;
  }

  // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : "";
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) {
      return "Guest";
    } else {
      return this.authState["displayName"] || "User without a Name";
    }
  }

  //// Email/Password Auth ////
  emailSignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        this.authState = user;
      })
      .catch(error => console.log(error, "AuthService", "emailsignup"));
  }

  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.authState = user;
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const auth0 = auth();
    return auth0
      .sendPasswordResetEmail(email)
      .then(() => console.log("email sent", "AuthService", "resetpassword"))
      .catch(error => console.log(error, "AuthService", "resetpassword"));
  }

  //// Sign Out ////
  signOut(): void {
    this.afAuth.signOut();
    this.router.navigate(["/"]);
  }

  private userToken() {
    if (this.afAuth.currentUser) {
      this.afAuth.idToken
        .toPromise()
        .then(token => (this._accessToken = token));
    }
  }

  public get accessToken(): string {
    return this._accessToken;
  }
}
