import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-drop-down",
  templateUrl: "./drop-down.component.html",
  styleUrls: ["./drop-down.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DropDownComponent implements OnInit, OnChanges {
  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() data: { key: any; value: any }[];
  @Input() inline: boolean;
  @Input() small: boolean;
  @Input() selectLabel: string = "";
  @Input() groupInline: boolean = false;
  @Input() classes: string = "";
  className = "f-10 form-group no-padding pl-2 pr-4 pt-3";
  selectLabelValue = "Select";
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes) {
    if (changes && !!changes.inline && changes.inline.currentValue) {
      this.className = "form-inline";
    }
    if (changes && changes.selectLabel && changes.selectLabel.currentValue) {
      this.selectLabelValue = changes.selectLabel.currentValue;
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === true
    ) {
      this.className = "f-10 form-group form-inline no-padding pl-2 pr-4 pt-3";
    }
    if (
      changes &&
      changes.groupInline &&
      changes.groupInline.currentValue === false
    ) {
      this.className = "f-10 form-group no-padding pl-2 pr-4 pt-3";
    }
    if (changes && changes.classes) {
      this.className = [this.className, changes.classes.currentValue].join(" ");
    }
  }
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.key == c2.key : c1 === c2;
  }
}
