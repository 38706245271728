import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-transform-table",
  templateUrl: "./transform-table.component.html",
  styleUrls: ["./transform-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TransformTableComponent implements OnInit {
  @Input() data: any[];
  @Input() apps: any[];
  @Output() logsClick = new EventEmitter();
  @Output() execClick = new EventEmitter();
  @Output() editClick = new EventEmitter();
  @Output() cloneClick = new EventEmitter();
  cols: any[];
  constructor() {
    this.cols = [
      { field: "job_name", header: "job" },
      { field: "job_description", header: "description" },
      // { field: "application_id", header: "application" }
    ];
  }

  ngOnInit(): void {}
  getName(id) {
    try {
      if (this.apps && this.apps.length > 0) {
        return this.apps.find((d) => d.key === id).value.toUpperCase();
      } else {
        throw "";
      }
    } catch {
      return null;
    }
  }

  edit(d) {
   
    const appname=this.getName(d.application_id)
   
    this.editClick.emit({d,app:appname});
  }
  logs(d) {
    this.logsClick.emit(d);
  }
  exec(d) {
    
    this.execClick.emit(d);
  }
  clone(d) {
    this.cloneClick.emit(d);
  }
}
