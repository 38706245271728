import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { FieldLayoutModel } from "src/app/dashboard/models/file-layout.model";

@Component({
  selector: "app-fields-layout-table",
  templateUrl: "./fields-layout-table.component.html",
  styleUrls: ["./fields-layout-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FieldsLayoutTableComponent implements OnInit {
  @Input() data: FieldLayoutModel[] = [];
  @Input() hideButton:boolean;
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  cols = [];
  constructor() {
    this.cols = [
      { header: "field name", field: "fieldName" },
      { header: "type", field: "fieldType" },
      { header: "format", field: "fieldFormat" },
      { header: "field size", field: "fieldSize" }
    ];
  }

  ngOnInit(): void {}
  editField(data) {
    this.edit.emit(data);
  }

 
  deleteField(data) {
    this.delete.emit(data);
  }

 
 
}
