import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LookupModel, PopUpModel } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import { exportCSVFile } from "src/app/utils/excel";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {
  activeRoute$: Subscription;
  lookupForm: FormGroup = null;
  lookupModel:LookupModel[];
  popUpModel:PopUpModel[];
  modalForm: FormGroup=null;
  lookUpData:any;
  visible:any;

  constructor(private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private loader: LoaderService,
    private fileService: FileService,
    private toast: ToastService,
    private lookupService: LookupService,
    private datePipe:DatePipe) {
      this.lookupForm=new FormGroup({
        lookupMap:new FormControl(null)  
      });
     
      this.modalForm = new FormGroup({
        client_id:new FormControl(null,[Validators.required]),
        asofdate:new FormControl(null,[Validators.required])
      });
    }



  ngOnInit(): void {
    this.setTitle();
    this.getLookupTable();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getLookupTable(){
    const lookupMap=[
      "accrualbasis_code",
      "branch_code",
      "call_report_code",
      "collateral_code",
      "line_of_credit_code",
      "loan_type_code",
      "non_accrual_code",
      "officer_code",
      "owner_occupied_code",
      "payment_frequency_code",
      "payment_type_code",
      "purpose_code",
      "rate_change_frequency_code",
      "reprice_index_code",
      "risk_rating_code",
      "system_fields_coll_code"

    ];
  
    return lookupMap.map((t) => ({ key: t, value: t }));
  }

  getLookupExcel() {
     if(this.modalForm.valid){
     var newDate=this.datePipe.transform(this.modalForm.controls.asofdate.value, 'yyyy/MM/dd');
     const modelVal={
      client_id:this.modalForm.controls.client_id.value.key,
      asofdate:newDate
    }
    // console.log(modelVal);
      this.lookupService.getLookupExcel(modelVal).subscribe(
        (data)=>{
          console.log(data);
          this.lookUpData=data;
         if(data && data.length > 0){
          exportCSVFile(null, data, "lookupfile");
        }
          this.toast.showSuccess('success','Excel Returned');
          this.modalForm.reset();
        },
        (err)=>{
          this.toast.showError('error',err.error.message);
        }

      )
      this.visible = false;  
     }else{
      this.toast.showError('error',"All Fields Are Mandatory");
     }
    }

    // this.modalForm.get("file").reset();
    // this.modalForm.get("client").reset();
   
  

  doAction(type) {
    if (type === "yes") {
      this.modalForm.get("client_id").reset();
       this.modalForm.get("asofdate").reset();
      this.visible=false;
    }
  }
  showPopup(data) {
    if(data.hasRules===0){
      this.toast.showInfo('info','file has no rules');
      return;
    }
   // this.cloneApplicationFileId=data.clientApplicationFileId;
    this.visible = true;
  }

  showModalPopUp(){
    this.visible=true;     
   }

   getSelected(e){
     console.log(e);
   }
}

