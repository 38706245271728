import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserModel } from 'src/app/dashboard/models/user.model';
import { FileService } from 'src/app/files/services/file.service';
import { Toast } from 'primeng/toast/toast';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-user-ops',
  templateUrl: './user-ops.component.html',
  styleUrls: ['./user-ops.component.scss']
})
export class UserOpsComponent implements OnInit {
  @Input() user;
  @Output() create = new EventEmitter();

  userForm: FormGroup = null;
  errorMessage: any;
  constructor(private fileService: FileService, private toast: ToastService) {
    this.initalizeForm();
  }

  initalizeForm() {
    this.userForm = new FormGroup({
      userName: new FormControl(null, { validators: [Validators.required, Validators.email], updateOn: "blur" }),
      firstName: new FormControl(null, { validators: [Validators.required, Validators.pattern('^[a-zA-Z]*$')], updateOn: "blur" }),
      lastName: new FormControl(null, { validators: [Validators.required, Validators.pattern('^[a-zA-Z]*$')], updateOn: "blur" }),
      // email:new FormControl(null, [Validators.required,),
      statusMap: new FormControl(null, [Validators.required]),
      createdBy: new FormControl(null),
    });
    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));

  }

  onValueChanged(data?: any) {
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.invalid && control.dirty) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          // this.formErrors[field] += messages[key] + ' ';
          this.errorMessage = messages;
          this.toast.showError('error', this.errorMessage);
          console.log(this.errorMessage);
        }
      }
    }
  }
  formErrors = {
    'userName': '',
    'firstName': '',
    'lastName': '',
    'Status': ''
  };
  // hasError(field: string, error: string) {
  //   const ctrl = this.userForm.get(field);
  //   return ctrl.dirty && ctrl.hasError(error);
  // }
  validationMessages = {
    'userName': 'UserName is invalid',
    'firstName':'FirstName is invalid',
    'lastName':'LastName is invalid',
    'status':'Please Select a status'
      }
  createUser() {
    if(this.userForm.valid){
    this.create.emit(this.userForm.value);
    console.log(this.userForm.value);

    //     if(this.userForm.valid){
    //    this.loader.showLoader();
    //     console.log(this.userForm.valid);
    //    if(this.userForm.controls.userId.value>0){
    //     this.userModel=this.userForm.value;
    //     this.fileService.edituser(this.userModel).subscribe(
    //       (data) => {
    //      this.toast.showSuccess("Field Updated", "success");
    //      this.userForm.reset();
    //      this.getAlluserList();
    //      this.loader.hideLoader();
    //    },
    //    (err) => {
    //      this.loader.hideLoader();
    //      this.toast.showSuccess("Field Not Updated", "error");
    //    }

    //  );
    //    }else{
    //    this.userModel=this.userForm.value;
    //    this.fileService.saveuser(this.userModel).subscribe(
    //      (data) => {
    //     this.toast.showSuccess("User Saved", "success");
    //     this.userForm.reset();

    //     this.loader.hideLoader();
    //   },
    //   (err) => {
    //     this.loader.hideLoader();
    //     this.toast.showSuccess("Field Not Saved", "error");
    //   }

    // );
    //    }
    //   }else{
    //     this.toast.showError("Please Fill Required Fields","error");
    //   }

  }else{
 this.toast.showError("All fields are mandatory","error");
  }
}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.user && changes.user.currentValue) {
      const d = changes.user.currentValue;
      console.log("RULE" + d.status);
      this.userForm.patchValue({
        userName: d.userName,
        firstName: d.firstName,
        lastName: d.lastName,
        status: d.status,
        createdBy: d.createdBy
      });
    }

  }
  get status() {
    const status = [
      "Active",
      "Deactive",
    ];
    return status.map((t) => ({ key: t, value: t }));
  }

  ngOnInit(): void {
    const data$ = this.userForm.valueChanges.subscribe((data) => {
     // console.log("I am data" + data)
    });
    // console.log("I am userops"+this.user);
  }
  ngAfterViewInit() {
   // console.log("I am userops" + this.user);
  }

}
