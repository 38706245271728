import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { Subscription } from 'rxjs';
import { FileService } from "src/app/files/services/file.service";
import { ClientFilesModel, LayoutFilesModel } from '../../models/files.model';
import { DataTransferModel } from '../../models/dataTransfer.model';

@Component({
  selector: 'app-layout-file-list',
  templateUrl: './layout-file-list.component.html',
  styleUrls: ['./layout-file-list.component.scss']
})
export class LayoutFileListComponent implements OnInit {

  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientFilesModel:ClientFilesModel[]=[]
  layoutFilesModel:LayoutFilesModel[]=[]
  columns: string[] = [
    "s no.",
    "file name",
    "type",
    "actions",
  ];
  currentPage:string='/layout-files';
  dataTransferModel:DataTransferModel;
  modalHeader: string;
  visible = false;
  modalForm: FormGroup = null;
  clientId: number = null;
  cloneApplicationFileId:number=null;

  constructor(
    private fileService: FileService,
    private loader: LoaderService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService
  ) { 

    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required])
      
    });

    this.modalForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      file:new FormControl(null, [Validators.required])
      
    });
  }

  ngOnInit(): void {
    this.setTitle();
    this.observalbleDataService.inventoryChanged$.subscribe(
      data=>{
        this.dataTransferModel=data;
        if(this.dataTransferModel.isRedirected===1){
        this.headerForm.get("client").patchValue({
          key: this.dataTransferModel.clientId,
          value: this.dataTransferModel.clientName,
        });
       
        this.getFilesData(this.headerForm.get("client").value);
       
       
      }
      }

    );
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getFilesData(client) {  
    this.loader.showLoader(); 
    // this.fileService.getFilesByClientId(client.key)
    // .subscribe(
    //   (data)=>{
    //      this.clientFilesModel=data;
    //   },
    //   (err)=>{
    //     this.toast.showError('error',err.error.message);
        
    //   },    
    // )
    this.fileService.getLayoutFilesByClientId(client.key)
    .subscribe(
        (data)=>{
           this.layoutFilesModel=data;
        },
        (err)=>{
          this.toast.showError('error',err.error.message);
          
        },    
      )
    this.loader.hideLoader(); 
  }

  navigateToPage(page: string, data: any) {
   
    const fileData = {
      clientId: data.clientId,
      clientFile: data.clientApplicationFileId,
      fileName: data.fileName,
      clientName: this.headerForm.value.client.value,
      fromDate:'',
      toDate:'',
      pageName:this.currentPage
    };
    this.router.navigate([page], {
      queryParams: { ...fileData },
    });
  }


  doAction(type) {
    if (type === "yes") {
      console.log("helloo");
     
    //  this.modalForm.reset();
     
    }
    this.modalForm.get("file").reset();
    this.modalForm.get("client").reset();
    
    this.clientId=null;
    this.visible = false;
  }

  showPopup(data) {
    if(data.hasRules===0){
      this.toast.showInfo('info','file has no rules');
      return;
    }
    this.cloneApplicationFileId=data.clientApplicationFileId;
    this.visible = true;
  }
  getFiles(){
    this.modalForm.controls.file.setValue(null);
     this.clientId=this.modalForm.controls.client.value.key
  }

  cloneRule(){
    if(this.cloneApplicationFileId===this.modalForm.controls.file.value.key){
      this.toast.showWarn('error','select another file');
      return;
    }
    this.visible=false;  
      const clientFileModel={
        "clientApplicationFileId":this.cloneApplicationFileId,
        "updatedClientApplicationFileId":this.modalForm.controls.file.value.key
      }
      this.fileService.cloneRulesToApplicationFiles(clientFileModel)
      .subscribe(
        (data)=>{
          console.log(data);
          this.toast.showSuccess('success','Rules cloned');
        },
        (err)=>{
          this.toast.showError('error',err.error.message);
        }

      )

      this.modalForm.reset();
      this.clientId=null;

    
  }

}
