import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
} from "@angular/core";
import { FormBuilderService } from "../../services/form-builder.service";
import { FormsModel } from "src/app/models/forms.model";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-builder",
  templateUrl: "./formbuilder.component.html",
  styleUrls: ["./formbuilder.component.scss"],
  providers: [FormBuilderService],
})
export class FormbuilderComponent implements OnChanges {
  @Input() model: FormsModel;
  @Input() formTitle: string;
  @Output() onSubmit = new EventEmitter();
  @Output() onLoad = new EventEmitter();
  @Input() inline: boolean;
  @Input() small: boolean;
  @Input() noSubmit: boolean;
  @Input() hideMessages: boolean;
  @Input() groupInline: boolean = false;
  form: FormGroup = null;
  inlineClass: string = "";
  constructor(private formService: FormBuilderService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes)
      if (changes.model) {
        this.form = this.formService.createFormGroup(
          changes.model.currentValue
        );
        this.onLoad.emit(this.form);
      }
    if (changes.inline) {
      this.inlineClass = "form-inline";
    }
  }
  formSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.onSubmit.emit(this.form.value);
    } else {
      console.log("invalid form state");
    }
  }
}
