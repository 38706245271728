import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from "@angular/core";
import { RulesModel } from "src/app/dashboard/models/rules.model";

@Component({
  selector: "app-rules-table",
  templateUrl: "./rules-table.component.html",
  styleUrls: ["./rules-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RulesTableComponent implements OnInit {
  @Input() data: RulesModel[] = [];
  @Input() hidebutton:boolean
  @Output() edit = new EventEmitter();
  @Output() apply = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() rulesListData=new EventEmitter();
  cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      { field: "ruleName", header: "Name of Rule" },
      { field: "appliesto", header: "Applied To" },
      { field: "userName", header: "Created By" },
      { field: "dateval", header: "Date" }
    ];
  }

  ngOnInit(): void {}

  applyRule(data: RulesModel) {
    console.log(data)
    this.apply.emit(data);
  }

  editRule(data: RulesModel) {
    this.edit.emit(data);
  }

  deleteRule(data: RulesModel) {
    this.delete.emit(data);
  }

  onRowChange(e){
    
     if(this.data.length>1 && this.hidebutton===false){
     this.rulesListData.emit(this.data)
     }
   }
}
