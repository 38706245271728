import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ClientService } from "src/app/dashboard/services/client.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { ToastService } from "src/app/core/services/toast.service";
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: "app-client-list",
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
  providers: [ClientService],
})
export class ClientListComponent implements OnInit {
  @Input() control: FormControl;
  @Output() onSelect = new EventEmitter();
  @Input() value: any;

  clientsList: any[];
  constructor(
    private clientService: ClientService,
    private toast: ToastService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.getClients();
  }
  getClients() {
    this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError("Server Error", "Unable to fetch data");
      }
    );
  }
  getSelected(val) {
    this.onSelect.emit(val);
  }
}
