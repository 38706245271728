import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { Subscription } from "rxjs";
import { FormsModel } from "src/app/models/forms.model";
import { Validators, FormGroup } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import { AuthService } from "../../services/auth.service";
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  loginModel: FormsModel = null;
  activeRoute$: Subscription;
  uService$: Subscription;
  /**
   * @param  {TitleService} privatetitleService
   * @param  {ActivatedRoute} privateactiveRoute
   * @param  {Router} privaterouter
   * @param  {ToastService} privatetoast
   */
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private authService: AuthService,
    private toast: ToastService
  ) {
    this.loginModel = {
      controls: [
        {
          label: "Email",
          type: "email",
          name: "email",
          validators: [Validators.required]
        },
        {
          label: "Password",
          type: "password",
          name: "password",
          validators: [Validators.required]
        }
      ]
    };
  }

  ngOnInit(): void {
    this.setTitle();
    this.checkUserStatus();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      data => this.titleService.setTitle(data["title"]),
      err => console.log("error", err)
    );
  }
  checkUserStatus() {
    this.uService$ = this.authService.currentUserObservable.subscribe(
      user => {
        if (user) {
          this.router.navigate(["/client-file"]);
        }
      },
      err => console.log("no user")
    );
  }
  /**
   * @param  {object} data
   */
  async doLogin(data: { email: string; password: string }) {
    try {
      this.loader.showLoader();
      const { email, password } = data;
      await this.authService.emailLogin(email, password);
      this.router.navigate(["client-file"]);
      this.loader.hideLoader();
    } catch (e) {
      this.toast.showError("Login Error", "Invalid Login Details");
      this.loader.hideLoader();
    }
  }
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
    this.uService$.unsubscribe();
  }
}
