import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ClientModel } from "src/app/dashboard/models/client.model";
import { environment } from "src/environments/environment";
import { ClientFilesModel, LayoutFilesModel } from "src/app/dashboard/models/files.model";
import { FileLogModel } from "src/app/dashboard/models/filelog.model";
import { formatDate } from "src/app/utils/dates";
import {
  TransformModel,
  JobsLogModel,
  TransformDataModel,
} from "src/app/dashboard/models/tranform.model";
import {
  FileLayoutModel,
  SheetModel,
  FieldLayoutModel,
} from "src/app/dashboard/models/file-layout.model";
import { FileMappingModel } from "src/app/dashboard/models/file-mapping.model";

@Injectable()
export class FileService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
  /**
   * @param  {number|string} clientId
   * @param  {any[]} dateRange
   */
  getFilesByClient(clientId: number | string, fromDate: string,toDate:string) {
    const data = {
      client_id: clientId,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    };
    return this.http.post<ClientModel[]>(
      environment.baseUrl + "/files/findbyclient",
      data
    );
  }
  /**
   * @param  {number|string} id
   */
  getFilesByClientId(id: number | string) {
    return this.http.get<ClientFilesModel[]>(
      `${environment.baseUrl}/client-application-file/findByClientId/${id}`
    );
  }
  /**
   * @param  {number|string} clientId
   * @param  {any[]} dateRange
   */
  getFilesCountByClient(clientId: number | string, fromDate:string,toDate:string) {
    const data = {
      client_id: clientId,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    };
    return this.http.post<ClientModel[]>(
      environment.baseUrl + "/files/findbyclientcount",
      data
    );
  }
  /**
   * @param  {number} id
   */
  getLogByClientFieldId(id: number) {
    return this.http.get<FileLogModel[]>(
      `${environment.baseUrl}/files/findbyclientid/${id}`
    );
  }
  /**
   * @param  {number} id
   */
  getTransformListByClient(id: number) {
    var payload = new FormData();
    payload.append("client_id", id.toString());
    return this.http.post<any>(
      `${environment.baseUrl}/api/etl/list/`,
      payload
    );
  }
  /**
   * @param  {number} id
   */
  getTransformLogByJob(id: number) {
    return this.http.get<JobsLogModel[]>(
      `${environment.baseUrl}/files/getTransformLogByJobId/${id}`
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getFileSheets(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<SheetModel[]>(
      `${environment.baseUrl}/files/findByClientIdAndFileTypeId`,
      data
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getFileLayout(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<FileLayoutModel>(
      `${environment.baseUrl}/file-layout/fileLayoutByClientFile`,
      data
    );
  }
  /**
   * @param  {} id
   */
  getFileLayoutById(id) {
    const url = `${environment.baseUrl}/file-layout/getFileLayoutFieldByLayoutId/${id}`;
    return this.http.get<FieldLayoutModel[]>(url);
  }
  getSheetHeaders(data: {
    clientApplicationFileId: number;
    clientId: number;
    sheetName: string;
    headerRows: number;
    noOfHeaders: number;
  }) {
    return this.http.post<FieldLayoutModel[]>(
      `${environment.baseUrl}/file-layout/readSheetHeaders`,
      data
    );
  }
  getFileLayoutByClientFile(client, file) {
    const data = { clientApplicationFileId: file, clientId: client };
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout/fileLayoutByClientFile`,
      data
    );
  }
  getFieldMappingsById(id) {
    return this.http.get<FileMappingModel[]>(
      `${environment.baseUrl}/clientFileMapping/getByClientApplicationFileId/${id}`
    );
  }

  ingestRuleBySequence(clientFileModel) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/ingestRule/ingestRuleBySequence/`,
      clientFileModel
    );
  }

  saveOrUpdateFileLayoutField(fileLayoutField) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout-field/save`,
      fileLayoutField
    );
  }

  saveFileLayout(fileLayout) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/file-layout/saveInfo`,
      fileLayout
    );
  }

  saveMapping(clientFileMapping) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/clientFileMapping/save`,
      clientFileMapping
    );
  }

  getAllLoanFields() {
    return this.http.get<string[]>(
      `${environment.baseUrl}/loans/getLoanColumns`
    );
  }

  getLoanColumnsByClientFileId(id) {
    return this.http.get<string[]>(
      `${environment.baseUrl}/loans/getLoanColumnsByClientFileId/${id}`
    );
  }

  executeJobById(id, job_parameters) {
    const body = new HttpParams()
      .set(`id`, id)
      .set(`job_parameters`, JSON.stringify(job_parameters));
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/transformation/`;
    return this.http.post(api, body.toString(), { headers });
  }

  getAllApplications() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/files/getAllApplication`
    );
  }
  cloneJobById(clientId, jobId, appId, name, desc) {
    const body = new HttpParams()
      .set(`client_id`, clientId)
      .set(`transformation_id`, jobId)
      .set("name", name)
      .set("description", desc)
      .set("application_id", appId);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/clone/`;
    return this.http.post(api, body.toString(), { headers });
  }

  deleteFileLayoutFieldById(id){
    return this.http.get<any[]>(
      `${environment.baseUrl}/file-layout-field/deleteById/${id}`
    );
    } 

    saveClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/save`,clientForm);
    }
    getAllClientList(){
      return this.http.get<any[]>(
        `${environment.baseUrl}/client/getAll`
      );
    }

    editClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/edit`,clientForm);
    }

    changeStatusForClient(clientForm){
      return this.http.post<ClientModel[]>(
        `${environment.baseUrl}/client/changeStatus`,clientForm);
    }

    cloneRulesToApplicationFiles(clientFormModel){
      return this.http.post(
        `${environment.baseUrl}/templaterule/mapRuleWithClientFile`,clientFormModel);
    }

    getLayoutFilesByClientId(id){
      return this.http.get<LayoutFilesModel[]>(
        `${environment.baseUrl}/files/getLayoutFilesByClientId/${id}`
      );
    }

    testAllRulesBySequence(clientFileModel) {
      return this.http.post(
        `${environment.baseUrl}/ingestRule/testAllRulesBySequence`,
        clientFileModel
      );
    }

    downloadFile(){
      let headers:HttpHeaders=new HttpHeaders();
     // headers=headers.append('Accept','text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9')
      headers=headers.append('Accept','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
 
      
      return this.http.get(
        `${environment.baseUrl}/ingestRule/download`,{headers}
      );
    }


}
