import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { SidebarService } from "src/app/core/services/sidebar.service";
import { environment } from "src/environments/environment";
import { TitleService } from "src/app/core/services/title.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/user/services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  title: string;
  titleService$: Subscription;
  constructor(
    private sidebar: SidebarService,
    private titleService: TitleService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.titleService$ = this.titleService.titleObservable.subscribe(
      title => (this.title = title),
      err => console.log("error", err)
    );
  }
  ngOnDestroy() {
    this.titleService$.unsubscribe();
  }
  signOut(){
     this.authService.signOut();
  }

  toggleStatus() {
    this.sidebar.toggleVisibility();
  }
}
