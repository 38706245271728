import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { FileLogModel } from "src/app/dashboard/models/filelog.model";

@Component({
  selector: "app-files-log",
  templateUrl: "./files-log.component.html",
  styleUrls: ["./files-log.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FilesLogComponent implements OnInit {
  @Input() data: FileLogModel[] = [];
  columns = ["file name", "log type", "code", "message", "date"];
  constructor() {}

  
  ngOnInit(): void {}
}
