import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { MenuService } from "../../services/menu.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  providers: [MenuService]
})
export class MenuComponent implements OnInit {
  items: MenuItem[];

  constructor(private menuService: MenuService) {}
  ngOnInit(): void {
    this.menuService.getMenuItems().subscribe(
      data => (this.items = data),
      err => console.log("error", err)
    );
  }
}
