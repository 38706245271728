import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TitleService } from "src/app/core/services/title.service";
import { RulesModel, RuleTypesModel } from "../../models/rules.model";
import { LoaderService } from "src/app/loader/services/loader.service";
import { FileService } from "src/app/files/services/file.service";
import { RulesService } from "src/app/ingestion-rules/services/rules.service";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { TemplateModel } from "../../models/template.model";
import { ToastService } from "src/app/core/services/toast.service";
import { RulesSelectorComponent } from "src/app/ingestion-rules/components/rules-selector/rules-selector.component";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import {  RuleTemplateVersion } from '../../models/ruleTemplate.model';
import { ClientFileIngetRuleModel } from '../../models/clientFileIngestRule.model';
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
@Component({
  selector: "app-rules",
  templateUrl: "./rules.component.html",
  styleUrls: ["./rules.component.scss"]
})
export class RulesComponent implements OnInit, OnDestroy {
  @ViewChild(RulesSelectorComponent, { read: false, static: false })
  rulesSelectorComponent: RulesSelectorComponent;
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientId: number = null;
  ingestionRules: RulesModel[] = [];
  templates: TemplateModel[] = [];
  allRules: RuleTypesModel[] = [];
  excelTemplate = null;
  fileLayout: any;
  fieldMappings: any[];
  selectedRule: any;
  newRule = 1;
  visible: boolean;
  predefinedRules: RulesModel[] = [];
  ruleTypes: { key: string | number; value: string }[];
  ruleNames: { key: string | number; value: string }[];
  typeAutocomplete = new FormControl();
  nameAutocomplete = new FormControl();
  showRulesForm = false;
  rawData: boolean = false;
  outputData: boolean = false;
  excelOutputTemplate: any[];
  clientFileIngestRuleId:number=null
  ingestRuleId:number=null
  saveAsTemplate:boolean
  saveAsVersion:boolean
  showTemplate:boolean
  
  ruleTemplateVersion:any[]=[];
  templateList: any;
  showTemplateDropdown: boolean=false;
  dataTransferModel:DataTransferModel;
  showBackButton:boolean=false;
  clientFileIngestRuleModel:ClientFileIngetRuleModel[]=[];
  toDate:string;
  fromDate:string;
  pageName:string;
  uniqueDates:any[]
  uniqueDatesData:any[]=[];
  loadLibraryRule:boolean=false;
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private ruleService: RulesService,
    private loader: LoaderService,
    private toast: ToastService,
    private fileService: FileService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(),
      file: new FormControl(),
      template:new FormControl(),
      version:new FormControl()
    });
  }
  getRuleName(id) {
    return this.allRules.find(d => d.ingestruletype_id === id).displayName;
  }
  ngOnInit(): void {
    this.setTitle();
    this.getTemplateNRules();
    this.getPredefinedRules();
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });
          this.getFiles();
        }
        if (par.has("fileName") && par.has("clientFile")) {
          const fileData = {
            key: par.get("clientFile"),
            value: par.get("fileName")
          };
          this.headerForm.get("file").setValue(fileData);
          this.getRules(fileData.key);
         
        }

        if(par.has("toDate") && par.has("fromDate")){
          this.toDate=par.get("toDate")
          this.fromDate=par.get("fromDate")
          this.showBackButton=true;
        }
        if(par.has("pageName")){
          this.pageName=par.get("pageName")
          
        }
      },
      err => {}
    );
  }
  getPredefinedRules() {
    this.loader.showLoader();
    this.ruleService.getAllIngestRules().subscribe(
      data => {
        this.predefinedRules = data;
        this.ruleNames = reMapForAutoComplete(
          this.predefinedRules,
          "",
          "ruleName"
        );
        
        this.loader.hideLoader();
      },
      err => this.loader.hideLoader()
    );
  }
  getTemplateNRules() {
    this.allRules = this.templates = [];   
    const rules = this.ruleService
      .getAllRulesType()
      .pipe(catchError(err => of(err.status)));
    const templates = this.ruleService
      .getAllTemplates()
      .pipe(catchError(err => of(err.status)));
    forkJoin([rules, templates]).subscribe(
      res => {
        this.allRules = res[0];
        this.templates = res[1];
        this.templateList=reMapForAutoComplete(this.templates,'id','name');
        
        this.ruleTypes=reMapForAutoComplete(
          this.allRules,
          "ingestruletype_id",
          "displayName"
        );
        
      },
      () => this.toast.showError("Server Error", "Unable to fetch data"),
      () => this.loader.hideLoader()
    );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      data => this.titleService.setTitle(data["title"]),
      err => console.log("error", err)
    );
  }
  getFiles() {
    this.clientId = this.headerForm.value.client.key;
    this.ingestionRules=[];
    this.ingestRuleId=null;
    this.excelTemplate=null;
    this.showTemplateDropdown=false
    this.ruleTemplateVersion=[];
    this.createRule();
    this.headerForm.get("file").reset();
    this.getListOfUniqueDate(this.clientId);
  }
  getRules(id) {
    this.loader.showLoader();
    this.ingestRuleId=null;
    this.ingestionRules=[]
    this.showTemplateDropdown=false
    this.ruleService.getIngestionRulesById(id).subscribe(
      data => {
        if(data!==null && data.length>0){
        this.showTemplateDropdown=false
        this.ingestionRules = data;
        this.ruleTemplateVersion=[];
       
        }else{
         // this.showTemplateDropdown=true
         this.showTemplateDropdown=false
        }
        const { client, file } = this.headerForm.value;      
        this.getFileLayout(client.key, file.key);
        this.getRawExcelData(client.key, file.key);
        this.loader.hideLoader();
      },
      err => {
        console.log(err);
        this.loader.showLoader();
      }
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getRawExcelData(client, file) {
    this.loader.showLoader();
    this.excelTemplate=null;
    this.ruleService.getRawData(client, file).subscribe(
      data => {
        this.rawData = true;
        this.excelTemplate = Object.values(data);
        this.loader.hideLoader();
      },
      err => {
        console.log(err);
        this.loader.hideLoader();
      }
    );
  }
  getFileLayout(client, file) {
    this.loader.showLoader();
    this.fileService.getFileLayoutByClientFile(client, file).subscribe(
      data => {
        if (data === null) {
          this.showTemplateDropdown=false;
          this.toast.showInfo("No Layout defined", "Select another file");
        } else {
          this.fileLayout = data;
          this.getFieldMapping(this.fileLayout.clientApplicationFileId);
        }
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }
  getFieldMapping(id) {
    this.loader.showLoader();
    this.fileService.getFieldMappingsById(id).subscribe(
      data => {
        this.fieldMappings = data;
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }
  editRule(data) {
    this.selectedRule = null;
    this.visible = false;
    this.showRulesForm = true;
    this.createRule();
    this.loader.showLoader();
   // console.log(" data :::",data);
    let clientfileIngetRuleLoadId=null;
    if(data.clientFileIngestRuleId===null || data.clientFileIngestRuleId===undefined || data.clientFileIngestRuleId===0){
     // console.log(data,' from load rule')
      clientfileIngetRuleLoadId=0;
    }
    if(data.clientFileIngestRuleId!==null && data.clientFileIngestRuleId!==undefined  && data.clientFileIngestRuleId>0){
     // console.log(data,' from load rule')
      this.clientFileIngestRuleId=data.clientFileIngestRuleId;
    }
   // this.ingestRuleId=data.ingestruleId;
    this.ruleService.getIngestionRuleByRuleId(data.ingestruleId).subscribe(
      res => {
        this.selectedRule = res;
        if(clientfileIngetRuleLoadId!==null){
        // console.log(clientfileIngetRuleLoadId+" hello here");
          this.loadLibraryRule=true;
        }
       // console.log(this.selectedRule)
        this.loader.hideLoader();
        this.excelOutputTemplate=null;
        this.outputData=false;
      },
      err => {
        this.ingestRuleId=null;
        this.loader.hideLoader();
      }
    );
  }
  showPopup() {
    this.visible = true;
    this.showRulesForm = false;
    this.ingestRuleId=null; 
  }
  createRule() {
    this.loadLibraryRule=false;
    this.visible = false;
    this.showRulesForm = true;
    this.newRule = this.newRule + 1;
    this.clientFileIngestRuleId=null;
    if(this.showTemplateDropdown){
      this.ingestionRules=[];
      this.showTemplateDropdown=false;
      this.templateList=[];
      this.ruleTemplateVersion=[]
    }

//console.log(this.loadLibraryRule+"    load librayry");
    
  }
  hidePopup(type) {
    this.visible = false; 
  }

  saveRuleData(data) {

    const { client, file } = this.headerForm.value;
   
    if(file.key===null){
      this.toast.showWarn('error','no file selected');
      return;
    }

    if(data.dataValue.ingestruletypeId===null || data.dataValue.ruleName===null || data.dataValue.appliesTo===null || data.dataValue.appliesTo.length===0){
      this.toast.showWarn('error','rule type,name & applies to mandatory fields');
      return;
    }

    if(data.dataValue.definition.errorValue===null || data.dataValue.definition.errorValue.length===0){
      this.toast.showWarn('error','error value required');
      return;
    }
  
   let validate= this.validationByRuleType(data);

   if(!validate){
     return;
   }
   
    if(data.ingestruleId==undefined){
      data.ingestruleId=null;  
    }
    
    this.loader.showLoader();
    const ingestionruleData={
      "ingestruletypeId":data.dataValue.ingestruletypeId,
      "ruleName":data.dataValue.ruleName,
      "appliesTo":data.dataValue.appliesTo,
      "ingestruleId": data.ingestruleId||0,
      "clientFileIngestRuleId":this.clientFileIngestRuleId!==null?this.clientFileIngestRuleId:null,
      "definition":{
        "compareTo":data.dataValue.definition.compareTo,
        "asOfDate":data.dataValue.definition.asOfDate,
        "operator":data.dataValue.definition.operator,
        "errorValue":data.dataValue.definition.errorValue,
        "updateValue":data.dataValue.definition.updateValue,
        "commentValue":data.dataValue.definition.commentValue,
        "rangeValue1":data.dataValue.definition.rangeValue1,
        "replaceValue":data.dataValue.definition.replaceValue,
        "operatedvalue":data.dataValue.definition.operatedvalue,
        "falseConditionValue":data.dataValue.definition.falseConditionValue,
        "updationValue":data.dataValue.definition.updationValue,
        "fieldType":data.dataValue.definition.fieldType,
        "minValue":data.dataValue.definition.minValue,
        "maxValue":data.dataValue.definition.maxValue
      }
    }

    if(this.loadLibraryRule){

      this.ruleService.mapLibraryRuleToClientFile(ingestionruleData,file.key).subscribe(
        res => {
          this.toast.showSuccess('success', 'rule saved');
          this.loader.hideLoader();
          this.resetChild();
          this.excelOutputTemplate=[];
          this.getRules(file.key);
          this.ingestRuleId=null;
          this.showExcel('raw');
          
          
        },
        err => {
          this.toast.showError('error',err.error.message);
          this.loader.hideLoader();
        }
      );
    }else{
      

    this.ruleService.saveRule(ingestionruleData,file.key).subscribe(
      res => {
        this.toast.showSuccess('success', 'rule saved');
        this.loader.hideLoader();
        this.resetChild();
        this.excelOutputTemplate=[];
        this.getRules(file.key);
        this.ingestRuleId=null;
        this.showExcel('raw');
        this.clientFileIngestRuleId=null;
      },
      err => {
        this.toast.showError('error',err.error.message);
        this.loader.hideLoader();
      }
    );
    }
  }

  applyRule(data) {
    this.showExcel('raw')
    const clientFileModel = {
      clientApplicationFileId: this.headerForm.controls.file.value.key,
      clientId: this.headerForm.controls.client.value.key,
      ingestRuleId: data.ingestruleId,
      logflag: false
    };
   
    this.excelOutputTemplate=[]
    this.loader.showLoader();
    this.ruleService.applyRule(clientFileModel).subscribe(
      res => {
        this.loader.hideLoader();
        this.excelOutputTemplate = Object.values(res);
        this.toast.showSuccess("Rule applied", "success");
        this.resetChild();
      },
      err =>{
        this.toast.showError('error', 'error while applying rule');
        this.loader.hideLoader()
      } 
    );
  }

  deleteRule(data) {
    this.loader.showLoader();
    const clientFileModel={
      "clientApplicationFileId":this.headerForm.controls.file.value.key,
      "ingestRuleId":data.ingestruleId,
      "clientFileIngestRuleId":data.clientFileIngestRuleId
    }
    this.ruleService.deleteIngestRule(clientFileModel).subscribe(
      res => {
        this.toast.showSuccess("Rule deleted", "success");
        this.loader.hideLoader();
        this.resetChild();
        this.excelOutputTemplate=[];
        this.showExcel('raw');
        this.getRules(this.headerForm.controls.file.value.key);
      },
      err => this.loader.hideLoader()
    );
  }

  resetChild(): void {
    this.showRulesForm = false;
    
  }

  showExcel(data) {
    if (data == "raw") {
      this.rawData = true;
      this.outputData = false;
    } else {
      this.rawData = false;
      this.outputData = true;
    }
  }

 
  changeSaveTemplate(e){
    this.saveAsTemplate=e.target.checked;
  }

  changeSaveVersion(e){
    this.saveAsVersion=e.target.checked;
  }

  saveTemplate(){

    if(!this.showTemplateDropdown){
      if(!this.saveAsTemplate){
        this.toast.showError('check save as template','error')
        return;
      }
    }

    if(this.ingestionRules.length===0){
      this.toast.showError('no rules in the list','error')
      return;
    }

    if(this.saveAsTemplate){
      const item={	
        createNewTemplate:true,
        ingestruleList:[],
        clientId:this.headerForm.controls.client.value.key,
        clientApplicationFileId:this.headerForm.controls.file.value.key,
        createNewVersion:true
       }	

      for(let i=0;i<this.ingestionRules.length;i++){	
        let templateData=this.ingestionRules[i].ingestruleId;	
        item.ingestruleList.push(templateData);	
       }	
  
       this.ruleService.saveDataForTemplate(item)
       .subscribe(
         res=>{
           this.toast.showSuccess('Template saved','success')
           console.log(res);
           this.resetChild();
          
           this.saveAsTemplate=false;
        this.excelOutputTemplate=[];
        this.getRules(this.headerForm.controls.file.value.key);
        this.ingestRuleId=null;
        this.showExcel('raw');
         }
       );
       
    }else if(this.saveAsVersion){
      const item={	
        templateId:1,
        createNewTemplate:false,
        ingestruleList:[],
        clientId:this.headerForm.controls.client.value.key,
        clientApplicationFileId:this.headerForm.controls.file.value.key,
        createNewVersion:true
       }	
       for(let i=0;i<this.ingestionRules.length;i++){	
        let templateData=this.ingestionRules[i].ingestruleId;	
        item.ingestruleList.push(templateData);	
       }	

       this.ruleService.saveDataForTemplate(item)
       .subscribe(
         res=>{
          this.toast.showSuccess('Template saved','success');
          this.resetChild();
          this.saveAsVersion=false;
          this.showTemplateDropdown=false;
          this.excelOutputTemplate=[];
          this.getRules(this.headerForm.controls.file.value.key);
          this.ingestRuleId=null;
          this.showExcel('raw');
         }
       );

    }else if(this.showTemplateDropdown){

    let arry=[]
    if(this.ingestionRules.length==0) {
     this.toast.showError('min one rule required','error')
     return;
    }
  for(let i=0;i<this.ingestionRules.length;i++){
    let idValue=this.ingestionRules[i].ingestruleId;
    arry.push(idValue);	
   }

   this.ruleService.saveTemplate(arry,this.headerForm.controls.file.value.key)
   .subscribe(
     res=>{
       this.toast.showSuccess('template saved','success');
       this.resetChild();
       this.showTemplateDropdown=false;
       this.excelOutputTemplate=[];
       this.getRules(this.headerForm.controls.file.value.key);
       this.ingestRuleId=null;
       this.showExcel('raw');
     },
     err=>this.toast.showError('error','error')
   )

    }



  }

 
  getVersion(id){
    this.loader.showLoader();
    this.ruleTemplateVersion=[]
    this.ingestionRules=[]
    this.ruleService.getTemplateVersionById(id)
    .subscribe(
      res=>{
        this.ruleTemplateVersion=reMapForAutoComplete(res,'ingestruletemplateversionId','versionNo')
        console.log(this.ruleTemplateVersion,' rule temp version')
        this.loader.hideLoader();
      }
    )
  }

  getRulesByVersion(id){
    this.ingestionRules=[];
    this.loader.showLoader();
    this.ruleService.getRulesByVersionId(id)
    .subscribe(
      res=>{
        this.ingestionRules=res;
        this.loader.hideLoader();
      }
      
    )

  }

  showLoad(e){
    console.log(e.target.checked);
  }

  validationByRuleType(data){

    if(data.dataValue.ingestruletypeId===5){
       if( data.dataValue.definition.updateValue===null){
         this.toast.showWarn('error','format field required')
         return false;
       }
    }

    if(data.dataValue.ingestruletypeId===3){
      if( data.dataValue.definition.operator===null || data.dataValue.definition.operator.length===0 || data.dataValue.definition.rangeValue1==null){
        this.toast.showWarn('error','operator and compare value field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===8){
      if(data.dataValue.definition.replaceValue===null){
        this.toast.showWarn('error','value field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===2){
      if(data.dataValue.definition.operator===null || data.dataValue.definition.operator.length===0 || data.dataValue.definition.rangeValue1===null || data.dataValue.definition.operatedvalue===null){
        this.toast.showWarn('error','operator,compare to & true value field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===9){
      if(data.dataValue.definition.replaceValue===null || data.dataValue.definition.updationValue===null){
        this.toast.showWarn('error','value & update to field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===7){
      if(data.dataValue.definition.updationValue===null){
        this.toast.showWarn('error','update to field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===13){
      if(data.dataValue.definition.fieldType===null || data.dataValue.definition.minValue===null || data.dataValue.definition.maxValue===null){
        this.toast.showWarn('error','field type,min value,max value required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===4){
      if(data.dataValue.definition.operator===null || data.dataValue.definition.operator.length===0 || 
        data.dataValue.definition.asOfDate===null || data.dataValue.definition.asOfDate.length===0 || data.dataValue.definition.compareTo===null
        ||data.dataValue.definition.compareTo.length===0){
        this.toast.showWarn('error','operator,asofDate,compareTo fields required')
        return false;
      }
    }

    return true;
  }


  onRowChange(data){
   
    for(let i=0;i<data.length;i++)
    {
        const clientFileIngestRule={
          "id":data[i].clientFileIngestRuleId,
          "clientApplicationFileId": this.headerForm.controls.file.value.key,
          "ingestrule_id": data[i].ingestruleId,
          "appliesto": data[i].appliesto ,
          "sequenceno": i+1,
          "comments":"",
          "createdBy":1,
          "createdAt":null,
          "modifiedBy":1,
          "modifiedAt":null,       
        }
        this.clientFileIngestRuleModel.push(clientFileIngestRule)
    }
    this.loader.showLoader();
    this.ruleService.saveClientFileIngestRule(this.clientFileIngestRuleModel)
    .subscribe(
      res=>{
       
        this.toast.showSuccess('Rules Sequence changed','Succcess');
        this.resetChild();
        this.excelOutputTemplate=[];
        this.loader.hideLoader();
        this.ingestRuleId=null;
        this.getRules(this.headerForm.controls.file.value.key);
         
        this.showExcel('raw');
      },
      err=>{
        this.toast.showError('server error','error');
        this.loader.hideLoader();
      }
      
    )
    


  }

  goToPage(){
    if(this.showBackButton){
    this.dataTransferModel={
      clientFileId:this.headerForm.controls.file.value.key,
      clientId: this.headerForm.controls.client.value.key,
           isRedirected:1,
           applicationId:0,
           toDate:this.toDate,
           fromDate:this.fromDate,
           clientName:this.headerForm.controls.client.value.value,
           page:this.pageName
    }
   // console.log(this.dataTransferModel);
    this.observalbleDataService.addToInventory(this.dataTransferModel);
    this.router.navigate([this.pageName]);
  
  }
}

getListOfUniqueDate(clientId){
  this.ruleService.getListOfUniqueDate(clientId).subscribe(
    data=>{
      this.uniqueDates=data;
       for(let i=0;i<this.uniqueDates.length;i++){
      this.uniqueDatesData.push({key:this.uniqueDates[i],value:this.uniqueDates[i]})
       }
      console.log(this.uniqueDatesData)
    }
  )

}

  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
  }
}
