import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastModule } from "primeng/toast";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserModule } from "./user/user.module";
import { CoreModule } from "./core/core.module";
import { HttpClientModule } from "@angular/common/http";
import { LoaderModule } from "./loader/loader.module";
import { ErrorModule } from "./error/error.module";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { DashboardModule } from "./dashboard/dashboard.module";
import { UiModule } from "./ui/ui.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    AppRoutingModule,
    DashboardModule,
    UiModule,
    AngularFireModule.initializeApp(environment.firebase),
    CoreModule.forRoot(),
    LoaderModule.forRoot(),
    ErrorModule.forRoot(),
    UserModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
