import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-check-list",
  templateUrl: "./check-list.component.html",
  styleUrls: ["./check-list.component.scss"]
})
export class CheckListComponent implements OnInit, OnChanges {
  @Input() ctrl: FormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() data: { key: any; value: string }[];
  checkBoxFields = [];
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.checkBoxFields = changes.data.currentValue.map(d => ({
        ...d,
        selected: false
      }));
      this.ctrl.setValue(this.checkBoxFields);
    }
  }
  updateData(field, checked) {
    this.checkBoxFields.find(p => p.key === field.key).selected = checked;
    this.ctrl.setValue(this.checkBoxFields);
  }
}
