import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { UserModel } from 'src/app/dashboard/models/user.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  getAllUserList(){
    return this.http.get<any[]>(
      `${environment.baseUrl}/users/getAllUsers`
    );
  }
  saveOrUpdateUser(user:UserModel) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/users/save`,
      user
    );
  }
}
