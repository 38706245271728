import { NgModule } from "@angular/core";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { ExcelModule } from "../excel/excel.module";
import { OrganizationOpsComponent } from "./pages/organization-ops/organization-ops.component";
import { TransformComponent } from "./pages/transform/transform.component";
import { ClientFileComponent } from "./pages/client-file/client-file.component";
import { ClientService } from "./services/client.service";
import { MappingComponent } from "./pages/mapping/mapping.component";
import { FilesModule } from "../files/files.module";
import { RulesComponent } from "./pages/rules/rules.component";
import { IngestionRulesModule } from "../ingestion-rules/ingestion-rules.module";
import { TransformListComponent } from "./pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./pages/file-layout/file-layout.component";
import { ClientComponent } from './pages/client/client.component';
import { LayoutFileListComponent } from './pages/layout-file-list/layout-file-list.component';
import { UserComponent } from './pages/user/user.component';
import { UserModule } from '../user/user.module';
import { LookupComponent } from './pages/lookup/lookup.component';
import { LookupService } from './services/lookup.service';
import { DatePipe } from '@angular/common';



@NgModule({
  declarations: [
    MainPageComponent,
    OrganizationOpsComponent,
    TransformComponent,
    ClientFileComponent,
    MappingComponent,
    RulesComponent,
    TransformListComponent,
    FileLayoutComponent,
    ClientComponent,
    LayoutFileListComponent,
    UserComponent,
    LookupComponent,
  ],
  imports: [
    SharedModule,
    UiModule,
    //DashboardRoutingModule,
    ExcelModule,
    FilesModule,
    IngestionRulesModule,
    UserModule
    
  ],
  providers: [ClientService,LookupService,DatePipe]
})
export class DashboardModule {}
