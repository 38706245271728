import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NavigationStart, Router, ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, filter } from "rxjs/operators";
import { FormGroup, FormControl } from "@angular/forms";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { LoaderService } from "src/app/loader/services/loader.service";
import { FileService } from "src/app/files/services/file.service";
import { FieldLayoutModel } from "../../models/file-layout.model";
import { ToastService } from "src/app/core/services/toast.service";
import { RulesService } from "src/app/ingestion-rules/services/rules.service";
import { TitleService } from "src/app/core/services/title.service";
import { FieldMappingsTableComponent } from "src/app/files/components/field-mappings-table/field-mappings-table.component";
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';

@Component({
  selector: "app-mapping",
  templateUrl: "./mapping.component.html",
  styleUrls: ["./mapping.component.scss"],
})
export class MappingComponent implements OnInit, OnDestroy {
  @ViewChild(FieldMappingsTableComponent, { read: false, static: false })
  fieldMappingsTableComponent: FieldMappingsTableComponent;
  state$: Observable<object>;
  filesList: any[] = [];
  headerForm: FormGroup = null;
  clientId: any = null;
  layoutFields: any[];
  fileLayout: any;
  excelTemplate :any;
  fieldMappings: any[];
  activeRoute$: Subscription;
  paramMap$: Subscription;
  mapData = new Map();
  dataTransferModel:DataTransferModel;
  showBackButton:boolean=false;
  toDate:string;
  fromDate:string;
  pageName:string;
  // loanFieldsList:string[];

  constructor(
    private toast: ToastService,
    private fileService: FileService,
    private ruleService: RulesService,
    private loader: LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(),
      file: new FormControl(),
    });
  }
  ngOnInit(): void {
    this.setTitle();
    this.paramMap$ = this.activeRoute.queryParamMap.subscribe(
      (par) => {      
        if (par.has("clientId") && par.has("clientName")) {      
          this.headerForm.get("client").patchValue({
            key: par.get("clientId"),
            value: par.get("clientName"),
          });
          this.getFiles(par.get("clientId"));
        }
        if (par.has("fileName") && par.has("clientFile")) {
          const fileData = {
            key: par.get("clientFile"),
            value: par.get("fileName"),
          };

          if(par.has("toDate") && par.has("fromDate")){
            this.toDate=par.get("toDate")
            this.fromDate=par.get("fromDate")
          }

          if(par.has("pageName")){
            this.pageName=par.get("pageName")
            
          }
         
          this.headerForm.get("file").setValue(fileData);
         
          this.getFileLayout(fileData);
          this.showBackButton=true;
        }
       // console.log(this.headerForm.controls.client.value.key+" mapp "+this.headerForm.controls.file.value.key)
      },
      (err) => {}
    );
   
  }
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
    this.paramMap$.unsubscribe();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getFiles(id) {
    this.clientId = id;
    this.fieldMappings = [];
    this.excelTemplate = null;
    this.headerForm.get("file").reset();
  }
  getFileLayout(file) {
    this.loader.showLoader();
    this.fieldMappings = [];
    const { client } = this.headerForm.value;
    this.getRawExcelData(client.key, file.key);
    this.fileService.getFileLayoutByClientFile(client.key, file.key).subscribe(
      (data) => {
        if (data === null) {
          this.toast.showInfo("No Layout defined", "Select another file");
        } else {
          this.fileLayout = data;
          this.getFieldLayout(this.fileLayout.clientFileLayoutId);
          this.getFieldMapping(this.fileLayout.clientApplicationFileId);
          // this.getLoanColumnsByClientFileId(this.fileLayout.clientApplicationFileId);
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
      }
    );
  }
  getFieldLayout(id) {
    this.loader.showLoader();
    this.fileService.getFileLayoutById(id).subscribe(
      (data) => {
        this.layoutFields = data.map((d) => ({
          key: d.clientFileLayoutFieldId,
          value: `${d.fieldName} (${d.fieldType})`,
        }));
        this.layoutFields.splice(0,0,{key:null,value:'Select Layout Field'})
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
      }
    );
  }
  getFieldMapping(id) {
    this.loader.showLoader();
    this.fileService.getFieldMappingsById(id).subscribe(
      (data) => {
        //this.fieldMappings = data;
       // console.log("here again");
        this.getLoanColumnsByClientFileId(
          this.fileLayout.clientApplicationFileId,
          data
        );

        const objects1 = Object.keys(data).map((key) => data[key]);
        objects1.forEach((element) => {
          let arry = [];
          arry.push(
            element.fieldName,
            element.clientFileLayoutFieldId,
            element.clientApplicationFileId,
            element.tableName,
            element.clientFileMappingId
          );
          this.mapData.set(element.fieldName, arry);
        });

        this.loader.hideLoader();
       
      },
      (err) => {
        this.loader.hideLoader();
      }
    );
  }
  getRawExcelData(client, file) {
    this.loader.showLoader();
   // this.excelTemplate = null;
    this.ruleService.getRawData(client, file).subscribe(
      (data) => {
        this.excelTemplate = Object.values(data);
        this.loader.hideLoader();
      },
      (err) => {
        console.log(err);
        this.loader.hideLoader();
      }
    );
  }


  updateField(data) {
    const arry = [
      data.fieldName,
      data.layoutFieldId,
      this.headerForm.controls.file.value.key,
      "loans",
      data.primaryKey,
    ];
    this.mapData.set(data.fieldName, arry);
    console.log("update", this.mapData);
  }

  saveMapping() {
    const dataToSave = [];
    

    for (let entry of this.mapData.entries()) {
      const saveData = entry[1];
      const dataMapping = {
        clientFileMappingId: saveData[4],
        clientApplicationFileId: saveData[2],
        clientFileLayoutFieldId: saveData[1],
        tableName: saveData[3],
        fieldName: saveData[0],
        createdBy: 1,
      };
      dataToSave.push(dataMapping);
    }
    this.loader.showLoader();
    this.fileService.saveMapping(dataToSave).subscribe(
      (res) => {
        this.toast.showSuccess("success", "Data Saved");
        this.loader.hideLoader();
        this.fieldMappings = [];
        this.layoutFields = [];
        this.fieldMappingsTableComponent.fields = [];
        this.mapData.clear();
        // this.fieldMappingsTableComponent.addArrayFields(0);
        // this.getFieldLayout
        // this.getFieldMapping(this.headerForm.controls.file.value.key);
        const fileData = {
          key: this.headerForm.controls.file.value.key,
          value: this.headerForm.controls.file.value.value,
        };
        this.getFileLayout(fileData);
      },
      (err) => {
        // this.toast.showError(err.error.message,"error")
        this.loader.hideLoader();
      }
    );
  }

  getLoanColumnsByClientFileId(id, dataVal) {
    this.loader.showLoader();
    this.fileService.getLoanColumnsByClientFileId(id).subscribe((data) => {
      // this.loanFieldsList=_.toArray(data);
      // this.fieldMappings=[...this.fieldMappings,...data];
     // console.log("here again");
      this.fieldMappings = [...dataVal, ...data];
      this.loader.hideLoader();
      //console.log(this.fieldMappings, " data here");
    });
    this.loader.hideLoader();
  }

  goToPage(){
    if(this.showBackButton){
    this.dataTransferModel={
           clientFileId:this.headerForm.controls.file.value.key,
           clientId: this.headerForm.controls.client.value.key,
           isRedirected:1,
           applicationId:0,
           toDate:this.toDate,
           fromDate:this.fromDate,
           clientName:this.headerForm.controls.client.value.value,
           page:this.pageName
    }
   // console.log(this.dataTransferModel);
    this.observalbleDataService.addToInventory(this.dataTransferModel);
    this.router.navigate([this.pageName]);
  
  }
}
}
