import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { FormsModel } from 'src/app/models/forms.model';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  clientList:any[];
  errorMessage:any;
 // formErrors:any[];
  //validationMessages:any;
  clientForm: FormGroup = null;
  clientModel:ClientModel[];
  activeRoute$: Subscription;
  hidebutton:boolean=false;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router) {
      this.clientForm=new FormGroup({
        clientId:new FormControl(null),
        clientName: new FormControl(null,{validators:[Validators.required,Validators.pattern("[a-zA-Z ]*")],updateOn: "blur"}),
        shortCd: new FormControl(null,[Validators.required]),
        cert: new FormControl(null,[Validators.required]),
        statusMap: new FormControl(null,{validators:[Validators.required]}),
        email: new FormControl(null,{validators:[Validators.email], updateOn: "blur"}),
        phone: new FormControl(null,{validators:[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")], updateOn: "blur"}),
        fax: new FormControl(null,{validators:[Validators.pattern("^[0-9]*$")], updateOn: "blur"}),
        contactPerson: new FormControl(null,{validators:[Validators.pattern("[a-zA-Z ]*")], updateOn: "blur"}),
        address1: new FormControl(null),
        address2: new FormControl(null),
        city: new FormControl(null),
        stateMap: new FormControl(null,[Validators.required]),
        zip: new FormControl(null, {validators:[Validators.pattern("^[0-9]*$")],updateOn:"blur"}),
        subClassCecl: new FormControl(false),
        subClassAll: new FormControl(false),
        callReportNameCecl: new FormControl(false),
        callReportNameAll: new FormControl(false),
        creditUnion: new FormControl(false),
        
      });
     this.clientForm.valueChanges.subscribe(data => this.onValueChanged(data));

    }
  ngOnInit(): void {
    this.setTitle();
    this.getAllClientList();
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.clientForm.get('statusMap').setValue(defaultMap);
    //console.log("this.clientForm " +this.clientForm.controls.statusMap.value);
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  saveClient(){
    if(this.clientForm.valid && this.clientForm.controls.stateMap.value.key!=null){
   this.loader.showLoader();
   if(this.clientForm.controls.clientId.value>0){
    this.clientModel=this.clientForm.value;
    this.fileService.editClient(this.clientModel).subscribe(
      (data) => {
     this.toast.showSuccess("Field Updated", "success");
     this.formReset();
     this.getAllClientList();
     this.loader.hideLoader();
   },
   (err) => {
     this.loader.hideLoader();
     this.toast.showSuccess("Field Not Updated", "error");
   }
 
 );
   }else{
   this.clientModel=this.clientForm.value;
   this.fileService.saveClient(this.clientModel).subscribe(
     (data) => {
    this.toast.showSuccess("Field Saved", "success");
    this.formReset();
    this.getAllClientList();
    this.loader.hideLoader();
  },
  (err) => {
    this.loader.hideLoader();
    this.toast.showSuccess("Field Not Saved", "error");
  }

);
   }
  }else{
    this.toast.showError("Please Fill Required Fields","error");
  }
}

getAllClientList(){
  this.loader.showLoader();
  this.fileService.getAllClientList().subscribe((data)=>{
    this.clientList=data;
    this.loader.hideLoader();
    if(!this.clientList.length){
      this.hidebutton=true;
    }else{
      this.hidebutton=false;
    }
  });
}

  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }


  get getstates(){
    const states = [
"Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"Florida",
"Georgia",
"Hawaii",
"Idaho",
"IllinoisIndiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"MontanaNebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"PennsylvaniaRhode Island",
"South Carolina",
"South Dakota",
"T,ennessee",
"Texas",
"Utah",
"Vermont",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming",

    ];
    return states.map((t) => ({ key: t, value: t }));
  }
  

  editClient(rowData){
      this.clientModel=rowData;
      this.clientForm.patchValue(
        {clientId:rowData.clientId,
          clientName: rowData.clientName,
          shortCd: rowData.shortCd,
          cert: rowData.cert,
          email: rowData.email,
          phone: rowData.phone,
          fax: rowData.fax,
          contactPerson: rowData.contactPerson,
          address1: rowData.address1,
          address2: rowData.address2,
          city: rowData.city,
          zip: rowData.zip,
          subClassAll:rowData.subClassAll,
          subClassCecl: rowData.subClassCecl,
          callReportNameCecl: rowData.callReportNameCecl,
          callReportNameAll: rowData.callReportNameAll,
          creditUnion: rowData.creditUnion,
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.clientForm.controls.statusMap.setValue(map1):this.clientForm.controls.statusMap.setValue(map2);

          
            const mapData = {
              key: rowData.state,
              value: rowData.state,
            };
            this.clientForm.controls.stateMap.setValue(mapData);
          
}

changeStatusForClient(rowData){
  this.clientModel=rowData;
   this.clientForm.patchValue(
    {clientId:rowData.clientId,   
    });
    if(rowData.status==true){
      const map = {
        key: "Deactive",
        value: "Deactive",
      };
      this.clientForm.controls.statusMap.patchValue(map);
      console.log(this.clientModel);
      this.clientModel=this.clientForm.value;
      this.fileService.changeStatusForClient(this.clientModel).subscribe(
        (data) => {
          this.toast.showSuccess("Status change", "success");
          this.formReset();
          this.getAllClientList();
          this.loader.hideLoader();
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
    
    }else{
      const map = {
        key: "Active",
        value: "Active",
      };
      this.clientForm.controls.statusMap.setValue(map);
      console.log(this.clientModel);
      this.clientModel=this.clientForm.value;
      this.fileService.changeStatusForClient(this.clientModel).subscribe(
        (data) => {
          this.toast.showSuccess("Status change", "success");
          this.formReset();
          this.getAllClientList();
          this.loader.hideLoader();
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
         
    }
    
 }
 formReset(){
   this.clientForm.reset();
   const defaultMap = {
    key: "Active",
    value: "Active",
  };
 this.clientForm.get('statusMap').setValue(defaultMap);
 }

 onValueChanged(data?: any) {
  if (!this.clientForm) { return; }
  const form = this.clientForm;
  for (const field in this.formErrors) {
   // clear previous error message (if any)
   this.formErrors[field] = '';
   const control = form.get(field);
   if (control && control.invalid && control.dirty) {
     const messages = this.validationMessages[field];
     for (const key in control.errors) {
      // this.formErrors[field] += messages[key] + ' ';
       this.errorMessage=messages;
        this.toast.showError('error',this.errorMessage);
        console.log(this.errorMessage);
      }
    }
   }
 }

 formErrors = {
  'email': '',
  'phone': '',
  'zip':'',
  'clientName':'',
  'fax':'',
  'contactPerson':'',
};

validationMessages = {
  'email':'Email is invalid',
 'phone':'Phone Number Should Not Exceed More Than 10 Digits',
 'zip':'Must Be Numeric Only',
  'clientName':'Must Be Alphabets Only',
  'fax':'Must Be Numeric Only',
  'contactPerson':'Must Be Alphabets Only',

  }
}