import { NgModule, ModuleWithProviders } from "@angular/core";
import { SidebarService } from "./services/sidebar.service";
import { HttpClientModule } from "@angular/common/http";
import { MessageService } from "primeng/api";

import { TitleService } from "./services/title.service";
import { ToastService } from "./services/toast.service";

@NgModule({ imports: [HttpClientModule] })
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [SidebarService, TitleService, ToastService, MessageService]
    };
  }
}
