import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./user/containers/login/login.component";
import { ErrorPageComponent } from "./error/components/error-page/error-page.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { OrganizationOpsComponent } from "./dashboard/pages/organization-ops/organization-ops.component";
import { ClientFileComponent } from "./dashboard/pages/client-file/client-file.component";
import { MappingComponent } from "./dashboard/pages/mapping/mapping.component";
import { RulesComponent } from "./dashboard/pages/rules/rules.component";
import { TransformListComponent } from "./dashboard/pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./dashboard/pages/file-layout/file-layout.component";
import { TransformComponent } from "./dashboard/pages/transform/transform.component";
import { ClientComponent } from "./dashboard/pages/client/client.component";
import { LayoutFileListComponent } from './dashboard/pages/layout-file-list/layout-file-list.component';
import { AuthGuard } from "./user/services/auth.guard";
import { UserComponent } from './dashboard/pages/user/user.component';
import { LookupComponent } from './dashboard/pages/lookup/lookup.component';

const routes: Routes = [

  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  {
    path: "org-ops",
    component: OrganizationOpsComponent,
    canActivate: [AuthGuard],
    data: { title: "Organization Ops" },
  },
  {
    path: "client-file",
    component: ClientFileComponent,
    canActivate: [AuthGuard],
    data: { title: "Client File" },
  },
  {
    path: "mapping",
    component: MappingComponent,
    canActivate: [AuthGuard],
    data: { title: "Mapping" },
  },
  {
    path: "rules",
    component: RulesComponent,
    canActivate: [AuthGuard],
    data: { title: "Ingestion Rules" },
  },
  {
    path: "transform",
    component: TransformComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform" },
  },
  {
    path: "transform-list",
    component: TransformListComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform List" },
  },
  {
    path: "file-layout",
    component: FileLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: "File Layout" },
  },
  {
    path: "client",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: { title: "Client" },
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: "User" },
  },
  {
    path: "external-data",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./external-data/external-data.module").then(
        (m) => m.ExternalDataModule
      ),
  },
  {
    path: "layout-files",
    canActivate: [AuthGuard],
    component: LayoutFileListComponent,
    data: { title: "Layout Files" },
  },

  {
    path: "lookup",
    canActivate: [AuthGuard],
    component: LookupComponent,
    data: { title: "Lookup" },
  },
  // {
  //   path: "dashboard",
  //   canActivate: [AngularFireAuthGuard],
  //   loadChildren: () =>
  //     import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  // },
  {
    path: "sample-pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
