import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LoaderService } from "src/app/loader/services/loader.service";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { currentMonthRange, dateBetween } from "src/app/utils/dates";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "src/app/core/services/toast.service";
import { FileLogModel } from "../../models/filelog.model";
import { FileService } from "src/app/files/services/file.service";
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
import { exportCSVFile } from "src/app/utils/excel";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-client-file",
  templateUrl: "./client-file.component.html",
  styleUrls: ["./client-file.component.scss"],
})
export class ClientFileComponent implements OnInit, OnDestroy {
  headerForm: FormGroup = null;
  clientsList: { key: number | string; value: string }[] = [];
  activeRoute$: Subscription;
  clientFiles: any[];
  clientFilesCount: any[];
  columns: string[] = [
    "s no.",
    "file name",
    "type",
    "status",
    "period",
    "actions",
  ];
  filesLog: FileLogModel[];
  dateRange$: Subscription;
  dataTransferModel:DataTransferModel;
  fromDt:any;
  toDt:any;
  currentPage:string='/client-file'
  
  constructor(
    private fileService: FileService,
    private loader: LoaderService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });
  }
  ngOnInit(): void {


    this.observalbleDataService.inventoryChanged$.subscribe(
      data=>{
        this.dataTransferModel=data;
        if(this.dataTransferModel.isRedirected===1){
        this.headerForm.get("client").patchValue({
          key: this.dataTransferModel.clientId,
          value: this.dataTransferModel.clientName,
        });
       
        
        this.fromDt=new Date(this.dataTransferModel.fromDate);
        this.toDt=new Date(this.dataTransferModel.toDate);
      // console.log(this.fromDt,'   lllll',this.toDt, '      kkk',this.dataTransferModel.fromDate,' ',isNaN(this.fromDt));
       if(this.fromDt!==null && this.toDt!==null && isNaN(this.fromDt)===false){
         this.headerForm.controls.fromDate.setValue(this.fromDt);
         this.headerForm.controls.toDate.setValue(this.toDt);
       }else{
         this.loadDate();
       }     
        this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
       
       
      }
      }

    );
    this.setTitle();
    //console.log(this.dataTransferModel,' datat transfer model')
    if(this.dataTransferModel.isRedirected===0){
      this.loadDate();
    }
    
    // this.dateRange$ = this.headerForm
    //   .get("dateRange")
    //   .valueChanges.subscribe((data) => {
    //     if (data[0] !== null && data[1] !== null) {
    //      // this.getFilesData(this.headerForm.get("client").value, data);
    //     }
    //   });
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getFilesData(client,fromDate,toDate) {
    this.clientFiles = this.clientFilesCount = []; // reset data
    this.loader.showLoader();
    this.filesLog=[]; 
    //const { dateRange } = this.headerForm.value;
    //console.log(fromDate,"  ",toDate);
    const files = this.fileService
      .getFilesByClient(client.key, fromDate,toDate)
      .pipe(catchError((err) => of(err.status)));
    const filesCount = this.fileService
      .getFilesCountByClient(client.key, fromDate,toDate)
      .pipe(catchError((err) => of(err.status)));
    forkJoin([files, filesCount]).subscribe(
      (res) => {
        this.clientFiles = res[0];
        this.clientFilesCount = res[1];
      },
      () => this.toast.showError("Server Error", "Unable to fetch data"),
      () => this.loader.hideLoader()
    );
   // this.dataTransferModel.isRedirected=0;
  }
  navigateToPage(page: string, data: any) {
   
    const fileData = {
      clientId: data.clientId,
      clientFile: data.clientApplicationFileId,
      fileName: data.fileName,
      clientName: this.headerForm.value.client.value,
      fromDate:this.headerForm.controls.fromDate.value,
      toDate:this.headerForm.controls.toDate.value,
      pageName:this.currentPage
    };
    this.router.navigate([page], {
      queryParams: { ...fileData },
    });
  }
  getLog(id) {
    this.loader.showLoader();
  
    this.fileService.getLogByClientFieldId(id).subscribe(
      (data) => {
        this.filesLog = data;
        
        this.loader.hideLoader();
      },
      () => this.loader.showLoader()
    );
  }
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
   // this.dateRange$.unsubscribe();
  }

  ingestRuleBySequence(data: any) {
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: true,
    };

    this.loader.showLoader();
    this.fileService.ingestRuleBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Ingested success');
        this.loader.hideLoader();
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError('error',err.error.message );
        this.loader.hideLoader()
      } 
    );
  }

  testAllRules(data: any) {
    //console.log(data.fileName);
    let fileName=data.fileName;
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: false,
      isTestAll:true
    };

    this.loader.showLoader();
    this.fileService.testAllRulesBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Test Success');
        this.loader.hideLoader();
        this.downloadExcel(fileName);
       
        
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError('error',err.error.message );
        this.loader.hideLoader()
      } 
    );
  }

  downloadExcel(fileName){
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/ingestRule/download/"+fileName;
   // document.body.appendChild(link);
    link.download="TestResuult.xlsx";
    link.click();
  }

  changeToDate(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  changeFromDate(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  }

  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0])
    this.headerForm.controls.toDate.setValue(currentDateRange[1])
  }


  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }




  
}
