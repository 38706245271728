import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) {}

  showSuccess(summary: string, detail: string) {
    this.messageService.add({
      severity: "success",
      summary,
      detail
    });
  }

  showInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: "info",
      summary,
      detail
    });
  }

  showWarn(summary: string, detail: string) {
    this.messageService.add({
      severity: "warn",
      summary,
      detail
    });
  }

  showError(summary: string, detail: string) {
    this.messageService.add({
      severity: "error",
      summary,
      detail
    });
  }
}
