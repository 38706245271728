import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  RulesModel,
  RuleTypesModel
} from "src/app/dashboard/models/rules.model";
import { environment } from "src/environments/environment";
import { TemplateModel } from "src/app/dashboard/models/template.model";
import { RuleTemplateVersion } from 'src/app/dashboard/models/ruleTemplate.model';

@Injectable()
export class RulesService {
  constructor(private http: HttpClient) {}
  /**
   * @param  {number} id
   */
  getIngestionRulesById(id: number) {
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllClientRules/${id}`
    );
  }
  getAllRulesType() {
    return this.http.get<RuleTypesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllRulesType`
    );
  }
  getAllTemplates() {
    return this.http.get<TemplateModel>(
      `${environment.baseUrl}/templaterule/getalltemplate`
    );
  }
  getRawData(clientId, fileId) {
    const data = { clientApplicationFileId: fileId, clientId: clientId };
    return this.http.post(
      `${environment.baseUrl}/ingestRule/rawDataUpload`,
      data
    );
  }
  getIngestionRuleByRuleId(id) {
    return this.http.get(
      `${environment.baseUrl}/ingestRule/findbyingestrule/${id}`
    );
  }

  saveRule(ruleCreateForm,clientId){

    return this.http.post(
      `${environment.baseUrl}/ingestRule/saveRule/${clientId}`,ruleCreateForm
    );
   }

   applyRule(clientFileModel){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/dataTransformation`,clientFileModel);  
   }

   deleteIngestRule(clientFileModel){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/deleteRuleById`,clientFileModel);
  
  }

  getAllIngestRules() {
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/ingestRule/getAllIngestRules`
    );
  }

  saveTemplate(arry,id){

    return this.http.post(
      `${environment.baseUrl}/ingestRule/mapRuleWithClientFile/${id}`,arry);
  }

  saveDataForTemplate(itemsList){	
    return this.http.post(
    `${environment.baseUrl}/templaterule/savetemplateversiondetail`,itemsList);
  	
   }

  getTemplateVersionById(id){	
   
    return this.http.get<RuleTemplateVersion[]>(
      `${environment.baseUrl}/templaterule/findtemplateversion/${id}`
    );
     
  }	

  getRulesByVersionId(id){
    return this.http.get<RulesModel[]>(
      `${environment.baseUrl}/templaterule/getversiondetail/${id}`
    );  
  }

  saveClientFileIngestRule(clientFileIngestRule){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/saveClientFileIngestRule`,clientFileIngestRule);
  }

  getListOfUniqueDate(id){
    return this.http.get<any[]>(
      `${environment.baseUrl}/loans/getUniqueDates/${id}`
    );  
  }


  mapLibraryRuleToClientFile(ruleCreateForm,fileId){
    return this.http.post(
      `${environment.baseUrl}/ingestRule/mapLibraryRuleToClientFile/${fileId}`,ruleCreateForm
    );
  }
}
