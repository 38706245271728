import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: "app-field-mappings-table",
  templateUrl: "./field-mappings-table.component.html",
  styleUrls: ["./field-mappings-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FieldMappingsTableComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() fields: { key: number | string; value: string }[] = [];
  @Input() fileMapping: any[] = [];
  @Output() onSelect = new EventEmitter();
  cols = [{ header: "system field name", field: "fieldName" }];
  fieldsArray = new FormGroup({ fields: new FormArray([]) });
  loadPage: boolean = false;
  filteredData: any[];
  constructor(private loader: LoaderService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.loader.showLoader();
      this.loadPage = false;
      this.addArrayFields(changes.data.currentValue.length);
    }
  }
  get fieldArray() {
    return this.fieldsArray.get("fields") as FormArray;
  }
  findField(id) {
    return this.fields.find((d) => d.key === id);
  }
  filterData(event) {
    let query = event.query;
    this.filteredData = this.fields.filter(
      (d) => d.value.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }
  addArrayFields(length) {
    this.fieldArray.clear();
    for (let i = 0; i < length; i++) {
      const arr = this.fieldArray;
      const data = this.findField(this.data[i].clientFileLayoutFieldId);
      arr.push(new FormControl(data));
    }
    this.loadPage = true;
    this.loader.hideLoader();
  }
  updateField(id, loanField, mappingId) {
    if (mappingId === undefined) {
      mappingId = null;
    }
    this.onSelect.emit({
      layoutFieldId: id,
      fieldName: loanField,
      primaryKey: mappingId,
    });
  }
}
