import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SidebarService {
  private _sidebarVisibility = false;
  private sidebarSubject = new BehaviorSubject<boolean>(
    this._sidebarVisibility
  );
  sidebarObservable = this.sidebarSubject.asObservable();
  constructor() {}

  toggleVisibility() {
    this._sidebarVisibility = !this._sidebarVisibility;
    this.sidebarSubject.next(this._sidebarVisibility);
  }
}
