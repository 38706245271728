import { Component, OnInit, OnDestroy } from "@angular/core";
import { SidebarService } from "src/app/core/services/sidebar.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  display: boolean = false;
  sidebar$: Subscription;
  constructor(private sidebar: SidebarService) {}

  ngOnInit(): void {
    this.sidebar$ = this.sidebar.sidebarObservable.subscribe(
      status => (this.display = status),
      err => console.log("error", err)
    );
  }
  toggleStatus() {
    this.sidebar.toggleVisibility();
  }
  ngOnDestroy() {
    if (this.sidebar$) {
      this.sidebar$.unsubscribe();
    }
  }
}
