import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { UserModel } from '../../models/user.model';
import { UserOpsComponent } from 'src/app/user/components/user-ops/user-ops.component';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  // @ViewChild(UserOpsComponent) userComponent;
  userList: UserModel[] = [];
  editUserValue=[];
  activeRoute$:Subscription;
  constructor(private userService: UserService,private toast:ToastService,private loader:LoaderService,
     private activeRoute: ActivatedRoute, private titleService: TitleService,) { }
  ngOnInit() {
    this.getAllUserList();
  }
  getAllUserList() {
    this.userService.getAllUserList().subscribe((data) => {
      this.userList = data;
      // this.loader.hideLoader();
      // if(!this.userList.length){
      //   this.hidebutton=true;
      // }else{
      //   this.hidebutton=false;
      // }
    }
    ,(err) => {
      this.toast.showError("Server Error", "Error fetching data");
      this.loader.hideLoader();
    }
    
    );
  }
 editUser(data){
    console.log("main component"+data)
    this.editUserValue=data;
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
addUser(data){
  console.log("I am new User"+data)
  const userData:UserModel = {
userName:data.userName,
firstName:data.firstName,
lastName:data. lastName,
// email:data.email,
status:data.status,
createdBy:data.createdBy

 
  };
  this.userService
    .saveOrUpdateUser(userData)
    .subscribe((data) => {
      this.getAllUserList();
    }); 
 
}

}
