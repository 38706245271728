import { uniq } from "lodash";
const DROPDOWNVALUES = {
  comparators: [
    { key: "=", value: "=" },
    { key: "!=", value: "!=" },
    { key: "<", value: "<" },
    { key: ">", value: ">" },
    { key: ">=", value: ">=" },
    { key: "<=", value: "<=" }
  ],
  errorValues: [
    { key: "error", value: "Stop Process" },
    { key: "warning", value: "Continue Process" }
  ],
  formats: [
    { key: "MM/dd/yyyy", value: "MM/dd/yyyy" },
    { key: "0-0-0-0-0", value: "Zip(first 5 values)" },
    { key: "%", value: "%(mul by 100)" },
    { key: "100", value: "/(divide by 100)" },
    { key: "1000", value: "/1000(divide by 1000)" },
    { key: "10000", value: "/10000(divide by 10000)" },
    { key: "100000", value: "/100000(divide by 100000)" },
    { key: "1000000", value: "/1000000(divide by 1000000)" }
  ],
  replaceValues: [
    { key: "n/a", value: "N/A" },
    { key: "nullvalue", value: "NULL" },
    { key: "0.0", value: "0" }
  ],
  fieldTypes: [
    { key: "date", value: "Date" },
    { key: "numeric", value: "Numeric" }
  ]
};


export const RULES_FIELDS = {
  compare: {
    controls: [
     // { label: "Compare To", name: "compareTo", type: "dropdown" },
     // { label: "As Of Date", name: "asOfDate", type: "dropdown" },
     {
      label: "Compare To",
      name: "compareTo",
      type: "dropdown",
      options: []    
    },
     {
      label: "As Of Date",
      name: "asOfDate",
      type: "dropdown",
      options: []
     
    },
      {
        label: "Comparison Operator",
        name: "operator",
        type: "dropdown",
        options: DROPDOWNVALUES.comparators,
        defaultValue: DROPDOWNVALUES.comparators[0]
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  format: {
    controls: [
      {
        label: "Format",
        name: "updateValue",
        type: "dropdown",
        options: DROPDOWNVALUES.formats
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  manual: {
    controls: [
      {
        label: "Comment",
        name: "commentValue",
        type: "text",
        placeholder: "Comment"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  remove: {
    controls: [
      {
        label: "Comparison Operator",
        name: "operator",
        type: "dropdown",
        options: DROPDOWNVALUES.comparators,
        defaultValue: DROPDOWNVALUES.comparators[0]
      },
      {
        label: "Compare Value",
        name: "rangeValue1",
        type: "text",
        placeholder: "Compare Value"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  removeDuplicate: {
    controls: [
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  removePredefined: {
    controls: [
      {
        label: "Value",
        name: "replaceValue",
        type: "dropdown",
        options: DROPDOWNVALUES.replaceValues
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  removeSpecialChar: {
    controls: [
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  translate: {
    controls: [
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  trim: {
    controls: [
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  update: {
    controls: [
      {
        label: "Comparison Operator",
        name: "operator",
        type: "dropdown",
        options: DROPDOWNVALUES.comparators,
        defaultValue: DROPDOWNVALUES.comparators[0]
      },
      {
        label: "Compare To",
        name: "rangeValue1",
        type: "text",
        placeholder: "Compare Value"
      },
      {
        label: "True Value",
        name: "operatedvalue",
        type: "text",
        placeholder: "Enter True Value"
      },
      {
        label: "False Value",
        name: "falseConditionValue",
        type: "text",
        placeholder: "Enter False Value"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  updateAll: {
    controls: [
      {
        label: "Update To",
        name: "updationValue",
        type: "text",
        placeholder: "Replace by Value"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  updatePredefined: {
    controls: [
      {
        label: "Value",
        name: "replaceValue",
        type: "dropdown",
        options: DROPDOWNVALUES.replaceValues
      },
      {
        label: "Update To",
        name: "updationValue",
        type: "text",
        placeholder: "Replace by Value"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  },
  validation: {
    controls: [
      {
        label: "Field Type",
        name: "fieldType",
        type: "dropdown",
        options: DROPDOWNVALUES.fieldTypes
      },
      {
        label: "Min Value",
        name: "minValue",
        type: "text",
        placeholder: "Enter Min Value"
      },
      {
        label: "Max Value",
        name: "maxValue",
        type: "text",
        placeholder: "Enter Max Value"
      },
      {
        label: "Error Case",
        name: "errorValue",
        type: "dropdown",
        options: DROPDOWNVALUES.errorValues,
        defaultValue: DROPDOWNVALUES.errorValues[0]
      }
    ]
  }
};
export function extraFields() {
  const keys = Object.keys(RULES_FIELDS);
  const fields = keys.map(k => {
    const { controls } = RULES_FIELDS[k];
    return { key: k, fields: controls.map(c => c.name) };
  });
  return fields;
}
export function uniqueFields() {
  const fields = extraFields();
  let data = [];
  for (let f of fields) {
    data = [...data, ...f["fields"]];
  }
  const uniqFields = uniq(data).map(d => ({ [d]: null }));
  return uniqFields.reduce(function(result, item) {
    var key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});
}
